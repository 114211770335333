import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CommaSeprator from "../components/TransactionsCommaSeprator.js";
import BreadCrumbHeader from "../components/Header/BreadCrumbHeader";
import SkeletonComponent from "../components/SkeletonComponent";
import useGetLoggedInUser from "../Hooks/LoggedInUser/useGetLoggedInUser";
import { HiDownload } from "react-icons/hi";
import "jspdf-autotable";
import GetCompanyNameNProfile from "../components/common/GetCompanyNameNProfile.js";
import * as XLSX from "xlsx/xlsx.mjs";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchWalletData,
  setCurrentPage,
} from "../redux/Slices/wallet_statement.js";

const TransactionsPage = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [initialPaginationCount, setInitialPaginationCount] = useState(null);
  const [searchData, setSearchData] = useState("");

  const walletState = useSelector((state) => state.wallet);
  const dispatch = useDispatch();

  const {
    statement,
    statementNoLimit,
    finalCount,
    currentPage,
    loading,
    error,
  } = walletState || {};

  const formatDateTime = (dateTimeString) => {
    const createdAtDateTime = new Date(dateTimeString);
    const timeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedTime = createdAtDateTime.toLocaleTimeString(
      "en-US",
      timeFormatOptions
    );

    return `${formattedTime}`;
  };

  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
    isRefetching,
  } = useGetLoggedInUser();

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  useEffect(() => {
    dispatch(fetchWalletData({ currentPage, userData, searchData }));
    setInitialPaginationCount(finalCount);
  }, [dispatch, currentPage, userData, searchData]);

  const handlePageChange = (event, newPage) => {
    if (newPage) {
      dispatch(setCurrentPage(newPage)); // Use the new page number
    }
  };

  return (
    <Paper elevation={0} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
      <BreadCrumbHeader MainText={"Transactions"} showbreadcrumb={true} />

      {loading ? (
        <>
          <SkeletonComponent />
        </>
      ) : (
        <>
          <Grid container>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "17px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                padding: "2rem",
              }}
              gap={2}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {statement?.length === 0 ? null : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Paper
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        alignContent: "center",
                        height: "50px",
                        background: "transparent",
                        boxShadow: "none",
                        padding: "0px",
                      }}
                    >
                      <GeneratePDFStatement
                        StatementData={statement?.slice()?.reverse()}
                        UserData={userData}
                      />
                    </Paper>
                  </Box>
                )}
              </Box>
              {loading ? (
                <>
                  <SkeletonComponent />
                </>
              ) : (
                <>
                  {statement?.length === 0 ? null : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <TableContainer sx={TableContainerStyle}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    No
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Date
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Company Name
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Order Number
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Type
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Debit
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Credit
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    TDS
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Account Balance
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Credit Due Balance
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Wallet Usable Balance
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Other
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    BXI Commission
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Assessable Value
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    GST
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Total Invoice Value
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    TDS
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Net Receivable/(Payable)
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    INR
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    Coins
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography sx={TableTitleStyle}>
                                    TDS (26AS)
                                  </Typography>
                                </TableCell>
                                <TableCell style={{ width: "300px" }}>
                                  <Typography sx={TableTitleStyle}>
                                    Invoice Value
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {statement?.map((row, index) => {
                                const wallet_release =
                                  Boolean(
                                    row?.OrderData?.commissionJuspayResponse
                                  ) ||
                                  row?._type === "refund" ||
                                  row?._refunded ||
                                  row?._from_wallet_id === "admin" ||
                                  (row?._document_type ===
                                    "delivery_confirmed" &&
                                    row?._from_wallet_id ===
                                      userData?.data?._id);

                                let price;
                                if (
                                  row._from_wallet_id === "admin" ||
                                  row._from_wallet_id === userData?.data?._id
                                ) {
                                  price = row._from_wallet_balance;
                                } else {
                                  price = row._to_wallet_balance;
                                }

                                const getTotalGST = (row) => {
                                  if (
                                    row?._from_wallet_id !== "admin" ||
                                    row?._type === "add_coupon" ||
                                    row?._type === "sell_coupon"
                                  ) {
                                    if (
                                      row?.OrderData?.BuyerChoosedTransportation
                                        ?.TransportationType === "Seller"
                                    ) {
                                      return (
                                        (row?.INRDetails
                                          ?.totalTransportationCostGST ?? 0) +
                                        (row?.INRDetails?.bxiCommisionGST ??
                                          0) +
                                        (row?.INRDetails?.totalGstAmount ?? 0) +
                                        (row?.INRDetails
                                          ?.InsuranceCostGstValue ?? 0) +
                                        (row?.INRDetails
                                          ?.bxiCommisionOnTransportationGST ??
                                          0) +
                                        (row?.INRDetails?.totalGstInrOfAdCost ??
                                          0) +
                                        (row?.INRDetails
                                          ?.packagingCostGSTValue ?? 0)
                                      );
                                    } else if (row?.OrderData?.IsMedia) {
                                      return (
                                        row?.INRDetails?.BxiCommisionGST +
                                        row?.INRDetails?.TotalGstAmount +
                                        row?.INRDetails?.TotalGstInrOfAdCost
                                      );
                                    } else {
                                      return (
                                        (row?.INRDetails?.bxiCommisionGST ??
                                          0) +
                                        (row?.INRDetails?.totalGstAmount ?? 0) +
                                        (row?.INRDetails?.totalGstInrOfAdCost ??
                                          0)
                                      );
                                    }
                                  } else {
                                    return null;
                                  }
                                };

                                let Amount =
                                  row?._balance + row?.INRDetails?.TDS;

                                const getTotalBalance = (row) => {
                                  if (
                                    row?.OrderData?.BuyerChoosedTransportation
                                      ?.TransportationType === "Seller"
                                  ) {
                                    return (
                                      (Amount ?? 0) +
                                      (row?.INRDetails
                                        ?.totalTransportationCost ?? 0) +
                                      (row?.INRDetails?.totalValueInrOfAdCost ??
                                        0) +
                                      (row?.INRDetails?.InsuranceCost ?? 0) +
                                      (row?.INRDetails?.packagingCost ?? 0) +
                                      (row?.INRDetails?.totalBxiCommission ?? 0)
                                    );
                                  } else if (row?.OrderData?.IsMedia) {
                                    return (
                                      (Amount ?? 0) +
                                      (row?.INRDetails?.TotalValueInrOfAdCost ??
                                        0) +
                                      (row?.INRDetails?.TotalBxiCommission ?? 0)
                                    );
                                  } else {
                                    return (
                                      (Amount ?? 0) +
                                      (row?.INRDetails?.totalValueInrOfAdCost ??
                                        0) +
                                      (row?.INRDetails?.totalBxiCommission ?? 0)
                                    );
                                  }
                                };

                                return (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        {index + 1}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        {convertDate(row?._created_at)}
                                        <br />
                                        at {formatDateTime(row?._created_at)}
                                      </Typography>
                                    </TableCell>
                                    {/* company profile */}
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <GetCompanyNameNProfile
                                          CompanyId={
                                            row?._from_wallet_id ===
                                            userData?.data?._id
                                              ? row?._to_wallet_id
                                              : row?._from_wallet_id
                                          }
                                        />
                                      </Typography>
                                    </TableCell>
                                    {/* order number */}
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        {row?._order_number?.includes("BXI")
                                          ? row?._order_number
                                          : row?._clnumber ||
                                            row?._order_number}
                                      </Typography>
                                    </TableCell>
                                    {/* Type */}
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        {row?._type === "sell_recover_coupon"
                                          ? "Sell/PrivateDeal"
                                          : row?._type === "recover_coupon"
                                          ? "Buy/PrivateDeal"
                                          : row?._type === "sell_coupon" &&
                                            row?._from_wallet_id !==
                                              userData?.data?._id
                                          ? "Sell/PrivateDeal"
                                          : row?._type === "sell_coupon"
                                          ? "Buy/PrivateDeal"
                                          : row?._type === "add_coupon"
                                          ? "Buy/PrivateDeal"
                                          : row?._type === "refund"
                                          ? "Refund"
                                          : row?._type?.includes("credit_line")
                                          ? "Credit Line"
                                          : row?._from_wallet_id ===
                                            userData?.data?._id
                                          ? "Buy"
                                          : "Sell"}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={
                                            row?._from_wallet_id ===
                                            userData?.data?._id
                                              ? Amount
                                              : row?._type ===
                                                "credit_line_recovery"
                                              ? Amount
                                              : null
                                          }
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={
                                            row?._from_wallet_id !==
                                            userData?.data?._id
                                              ? row?._type === "add_coupon"
                                                ? Amount
                                                : row?._from_wallet_id ===
                                                    "admin" &&
                                                  row?._type === "sell_coupon"
                                                ? Amount
                                                : row?._from_wallet_id ===
                                                  "admin"
                                                ? null
                                                : Amount
                                              : null
                                          }
                                        />
                                      </Typography>
                                    </TableCell>

                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={row?.INRDetails?.TDS}
                                        />
                                      </Typography>
                                    </TableCell>
                                    {/* account balance */}
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        {price < 0 ? (
                                          <>
                                            -
                                            <CommaSeprator
                                              Price={Math.abs(price)}
                                            />
                                          </>
                                        ) : (
                                          <CommaSeprator Price={price} />
                                        )}
                                      </Typography>
                                    </TableCell>
                                    {/* credit due */}
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={
                                            row?._type === "add_coupon"
                                              ? row?._from_wallet_credit_due
                                              : row?._from_wallet_id ===
                                                  "admin" &&
                                                row?._type === "sell_coupon"
                                              ? row?._from_wallet_credit_due?.toFixed(
                                                  2
                                                )
                                              : row?._from_wallet_id === "admin"
                                              ? row?._to_wallet_credit_due?.toFixed(
                                                  2
                                                )
                                              : row?._from_wallet_id ===
                                                userData?.data?._id
                                              ? row?._from_wallet_credit_due?.toFixed(
                                                  2
                                                )
                                              : row?._to_wallet_credit_due?.toFixed(
                                                  2
                                                )
                                          }
                                        />
                                      </Typography>
                                    </TableCell>
                                    {/* Wallet Usable Balance */}
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={
                                            row?._type === "add_coupon"
                                              ? row?._from_wallet_usable_balance
                                              : row?._from_wallet_id ===
                                                  "admin" &&
                                                row?._type === "sell_coupon"
                                              ? row?._from_wallet_usable_balance?.toFixed(
                                                  2
                                                )
                                              : row?._from_wallet_id === "admin"
                                              ? row?._to_wallet_usable_balance?.toFixed(
                                                  2
                                                )
                                              : row?._from_wallet_id ===
                                                userData?.data?._id
                                              ? row?._from_wallet_usable_balance?.toFixed(
                                                  2
                                                )
                                              : row?._to_wallet_usable_balance?.toFixed(
                                                  2
                                                )
                                          }
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        {row?.OrderData
                                          ?.BuyerChoosedTransportation
                                          ?.TransportationType === "Seller" ? (
                                          <CommaSeprator
                                            Price={
                                              row?.INRDetails
                                                ?.totalTransportationCost +
                                                row?.INRDetails
                                                  ?.totalValueInrOfAdCost +
                                                row?.INRDetails?.packagingCost +
                                                row?.INRDetails
                                                  ?.InsuranceCost || 0
                                            }
                                          />
                                        ) : (
                                          <CommaSeprator
                                            Price={
                                              row?.INRDetails
                                                ?.totalValueInrOfAdCost ||
                                              row?.INRDetails
                                                ?.TotalValueInrOfAdCost ||
                                              0
                                            }
                                          />
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={
                                            row?.INRDetails
                                              ?.TotalBxiCommission ||
                                            row?.INRDetails
                                              ?.totalBxiCommission ||
                                            0
                                          }
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={getTotalBalance(row)}
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={getTotalGST(row)}
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={
                                            getTotalBalance(row) +
                                            getTotalGST(row)
                                              ? (
                                                  getTotalBalance(row) +
                                                  getTotalGST(row)
                                                )?.toFixed(2)
                                              : 0
                                          }
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={row?.INRDetails?.TDS}
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={
                                            row?._from_wallet_id !==
                                            userData?.data?._id
                                              ? getTotalBalance(row) +
                                                getTotalGST(row) -
                                                row?.INRDetails?.TDS
                                              : getTotalBalance(row) +
                                                getTotalGST(row)
                                          }
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={row?.INRDetails?.TotalInrToPay}
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator Price={row?._balance} />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={
                                            row?._from_wallet_id !==
                                            userData?.data?._id
                                              ? row?.INRDetails?.TDS
                                              : null
                                          }
                                        />
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography
                                        sx={{
                                          ...TextStyleCommon,
                                          color: wallet_release
                                            ? "black"
                                            : "#FF8E00",
                                        }}
                                      >
                                        <CommaSeprator
                                          Price={
                                            getTotalBalance(row) +
                                            getTotalGST(row)
                                              ? (
                                                  getTotalBalance(row) +
                                                  getTotalGST(row)
                                                )?.toFixed(2)
                                              : 0
                                          }
                                        />
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {finalCount > 0 ? (
            <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
              <Stack spacing={2}>
                <StyledPagination
                  count={initialPaginationCount}
                  color="primary"
                  page={currentPage}
                  onChange={(event, newPage) =>
                    handlePageChange(event, newPage)
                  }
                  showFirstButton
                  showLastButton
                />
              </Stack>
            </Box>
          ) : null}
        </>
      )}
    </Paper>
  );
};

export default TransactionsPage;

const GeneratePDFStatement = (props) => {
  let ExcelData = props?.StatementData;
  const convertDate = (date) => {
    return new Date(date);
  };

  const generatePDFStatement = async () => {
    async function getCompanyByName(props) {
      let datda = await axios
        .get(`company/get_company/${props}`)
        .then((res) => {
          return res?.data?.companyName;
        });

      return datda;
    }

    const wb = XLSX.utils.book_new();

    const headerRows = [
      [`Company Name: ${props?.UserData?.data?.companyName}`],
      [`Email: ${props?.UserData?.data?.email}`],
      [`Address: ${props?.UserData?.data?.RegisteredAddress}`],
      [`Statement Date: ${new Date().toDateString()}`],
      [],
      [
        "Sr No",
        "Date",
        "Company Name",
        "Order Number",
        "Type",
        "Debit",
        "Credit",
        "TDS",
        "Account Balance",
        "Credit Due Balance",
        "Wallet Usable Balance",
        "Other",
        "BXI Commission",
        "Assessable Value",
        "GST",
        "Total Invoice Value",
        "TDS",
        "Net Receivable/(Payable)",
        "INR",
        "Coins",
        "TDS (26AS)",
        "Invoice Value",
      ],
    ];

    const infoWs = XLSX.utils.aoa_to_sheet(headerRows);

    await Promise.all(
      ExcelData?.map(async (row, index) => {
        const getTotalGST = (row) => {
          if (
            row?._from_wallet_id !== "admin" ||
            row?._type === "add_coupon" ||
            row?._type === "sell_coupon"
          ) {
            if (
              row?.OrderData?.BuyerChoosedTransportation?.TransportationType ===
              "Seller"
            ) {
              return (
                (row?.INRDetails?.totalTransportationCostGST ?? 0) +
                (row?.INRDetails?.bxiCommisionGST ?? 0) +
                (row?.INRDetails?.totalGstAmount ?? 0) +
                (row?.INRDetails?.InsuranceCostGstValue ?? 0) +
                (row?.INRDetails?.bxiCommisionOnTransportationGST ?? 0) +
                (row?.INRDetails?.totalGstInrOfAdCost ?? 0) +
                (row?.INRDetails?.packagingCostGSTValue ?? 0)
              );
            } else if (row?.OrderData?.IsMedia) {
              return (
                row?.INRDetails?.BxiCommisionGST +
                row?.INRDetails?.TotalGstAmount +
                row?.INRDetails?.TotalGstInrOfAdCost
              );
            } else {
              return (
                (row?.INRDetails?.bxiCommisionGST ?? 0) +
                (row?.INRDetails?.totalGstAmount ?? 0) +
                (row?.INRDetails?.totalGstInrOfAdCost ?? 0)
              );
            }
          } else {
            return 0;
          }
        };

        let Amount = row?._balance + row?.INRDetails?.TDS;

        const getTotalBalance = (row) => {
          if (
            row?.OrderData?.BuyerChoosedTransportation?.TransportationType ===
            "Seller"
          ) {
            return (
              (Amount ?? 0) +
              (row?.INRDetails?.totalTransportationCost ?? 0) +
              (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
              (row?.INRDetails?.InsuranceCost ?? 0) +
              (row?.INRDetails?.packagingCost ?? 0) +
              (row?.INRDetails?.totalBxiCommission ?? 0)
            );
          } else if (row?.OrderData?.IsMedia) {
            return (
              (Amount ?? 0) +
              (row?.INRDetails?.TotalValueInrOfAdCost ?? 0) +
              (row?.INRDetails?.TotalBxiCommission ?? 0)
            );
          } else {
            return (
              (Amount ?? 0) +
              (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
              (row?.INRDetails?.totalBxiCommission ?? 0)
            );
          }
        };

        const otherBalance = (row) => {
          return row?.OrderData?.BuyerChoosedTransportation
            ?.TransportationType === "Seller"
            ? row?.INRDetails?.totalTransportationCost +
                row?.INRDetails?.totalValueInrOfAdCost +
                row?.INRDetails?.packagingCost +
                row?.INRDetails?.InsuranceCost || "-"
            : row?.INRDetails?.totalValueInrOfAdCost ||
                row?.INRDetails?.TotalValueInrOfAdCost ||
                0;
        };

        // let Amount = (row?._balance + row?.INRDetails?.TDS).toFixed(2);

        let companyNamessss =
          (await getCompanyByName(
            row?._from_wallet_id === props?.UserData?.data?._id
              ? row?._to_wallet_id
              : row?._from_wallet_id
          )) || "Barter Exchange of India";

        let _closing_balance = 0;

        switch (true) {
          case row?._from_wallet_id === props?.UserData?.data?._id:
            _closing_balance = row?._closing_balance_buyer?.toFixed(2);
            break;
          case row?._to_wallet_id !== props?.UserData?.data?._id:
            _closing_balance = row?._closing_balance_seller?.toFixed(2);
            break;
          default:
            _closing_balance = row?._closing_balance_buyer?.toFixed(2);
            break;
        }
        if (row?._type === "credit_line") {
          _closing_balance = row?._closing_balance_buyer?.toFixed(2);
        }

        const rowData = [
          // srno
          index + 1,
          // Date
          convertDate(row._created_at)?.toLocaleString("en-IN"),
          // compny name
          companyNamessss,
          // Order number
          row?._order_number?.includes("BXI")
            ? row?._order_number
            : row?._clnumber || row?._order_number,
          // type
          row?._type === "sell_recover_coupon"
            ? "Sell/PrivateDeal"
            : row?._type === "recover_coupon"
            ? "Buy/PrivateDeal"
            : row?._type === "sell_coupon" &&
              row?._from_wallet_id !== props?.UserData?.data?._id
            ? "Sell/PrivateDeal"
            : row?._type === "sell_coupon"
            ? "Buy/PrivateDeal"
            : row?._type === "add_coupon"
            ? "Buy/PrivateDeal"
            : row?.type === "refund"
            ? "Refund"
            : row?._type?.includes("credit_line")
            ? "Credit Line"
            : row?._from_wallet_id === props?.UserData?.data?._id
            ? "Buy"
            : "Sell",
          // Debit
          row?._from_wallet_id === props?.UserData?.data?._id
            ? Amount.toFixed(2)
            : row?._type === "credit_line_recovery"
            ? Amount.toFixed(2)
            : null,
          // credit
          row?._from_wallet_id !== props?.UserData?.data?._id
            ? row?._type === "add_coupon"
              ? Amount?.toFixed(2)
              : row?._from_wallet_id === "admin" && row?._type === "sell_coupon"
              ? Amount?.toFixed(2)
              : row?._from_wallet_id === "admin"
              ? null
              : Amount?.toFixed(2)
            : null,
          // TDS
          row?.INRDetails?.TDS ? row?.INRDetails?.TDS.toFixed(2) : null,
          // acc balance
          row._from_wallet_id === props?.UserData?.data?._id ||
          row._from_wallet_id === "admin"
            ? row?._from_wallet_balance?.toFixed(2)
            : row?._to_wallet_balance?.toFixed(2),
          // Credit Due Balance
          row?._type === "add_coupon"
            ? row?._from_wallet_credit_due
            : row?._from_wallet_id === "admin" && row?._type === "sell_coupon"
            ? row?._from_wallet_credit_due?.toFixed(2)
            : row?._from_wallet_id === "admin"
            ? row?._to_wallet_credit_due?.toFixed(2)
            : row?._from_wallet_id === props?.UserData?.data?._id
            ? row?._from_wallet_credit_due?.toFixed(2)
            : row?._to_wallet_credit_due?.toFixed(2),
          // Wallet Usable Balance
          row?._type === "add_coupon"
            ? row?._from_wallet_usable_balance
            : row?._from_wallet_id === "admin" && row?._type === "sell_coupon"
            ? row?._from_wallet_usable_balance?.toFixed(2)
            : row?._from_wallet_id === "admin"
            ? row?._to_wallet_usable_balance?.toFixed(2)
            : row?._from_wallet_id === props?.UserData?.data?._id
            ? row?._from_wallet_usable_balance?.toFixed(2)
            : row?._to_wallet_usable_balance?.toFixed(2),
          // Other
          otherBalance(row),
          // BXI Commission
          row?.INRDetails?.TotalBxiCommission?.toFixed(2) ||
            row?.INRDetails?.totalBxiCommission?.toFixed(2) ||
            0,
          // Assessable Value
          getTotalBalance(row) ? getTotalBalance(row).toFixed(2) : null,
          // GST
          getTotalGST(row) ? getTotalGST(row).toFixed(2) : null,
          // Total Invoice Value
          getTotalBalance(row) + getTotalGST(row)
            ? (getTotalBalance(row) + getTotalGST(row))?.toFixed(2)
            : null,
          // TDS
          row?.INRDetails?.TDS?.toFixed(2),
          // Net Receivable/(Payable)
          row?._from_wallet_id !== props?.UserData?.data?._id
            ? (
                getTotalBalance(row) +
                getTotalGST(row) -
                (row?.INRDetails?.TDS || 0)
              ).toFixed(2)
            : (getTotalBalance(row) + getTotalGST(row)).toFixed(2),
          // INR
          row?.INRDetails?.TotalInrToPay?.toFixed(2),
          // Coins
          row?._balance?.toFixed(2),
          // TDS (26AS)
          row._from_wallet_id !== props?.UserData?.data?._id
            ? row?.INRDetails?.TDS?.toFixed(2)
            : null,
          // Invoice Value
          getTotalBalance(row) + getTotalGST(row)
            ? (getTotalBalance(row) + getTotalGST(row))?.toFixed(2)
            : null,
        ];

        XLSX.utils.sheet_add_aoa(infoWs, [rowData], { origin: index + 7 });
      })
    );

    XLSX.utils.book_append_sheet(wb, infoWs, "Statement");

    XLSX.writeFile(
      wb,
      `${props?.UserData?.data?.CUID}-${new Date().toISOString()}.xlsx`
    );
  };

  return (
    <Button
      onClick={generatePDFStatement}
      sx={{
        background: "#445FD2",
        borderRadius: "17px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        textTransform: "none",
        color: "#F8F8F8",
        width: "200px",
        height: "40px",
        "&:hover": {
          background: "#445FD2",
        },
      }}
    >
      <HiDownload fill="white" size={20} /> Download Statement
    </Button>
  );
};

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#000",
};

const TableContainerStyle = {
  minHeight: "550px",
  maxHeight: "550px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};
