import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const UnsubscribeForm = () => {
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setSubmitted(true);
  };

  async function unsubscribe() {
    await axios
      .post("/communcations/unsubscribeEmails", {
        email: email,
        reasons: reason,
      })
      .then(
        (response) => {
          toast.success("Unsubscribed Successfully");
        },
        (error) => {}
      );
  }

  // Custom inline style for the button
  const buttonStyle = {
    fontSize: "20x",
    height: "40px",
    marginTop: "10px",
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        alignContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <ToastContainer position="top-center" autoClose={3000} />
      <Box
        sx={{
          width: "400px",
          height: "200px",
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            gap: "10px",
          }}
        >
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            required
            disabled={submitted}
            margin="normal"
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Reason (Optional)</InputLabel>
            <Select
              label="Reason (Optional)"
              value={reason}
              onChange={handleReasonChange}
              disabled={submitted}
            >
              <MenuItem value="No specific reason">No specific reason</MenuItem>
              <MenuItem value="Too many emails">Too many emails</MenuItem>
              <MenuItem value="Content not relevant">
                Content not relevant
              </MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={buttonStyle} // Apply the custom style here
            // disabled={submitted}
            onClick={unsubscribe}
          >
            <Typography sx={{ textTransform: "none", fontSize: "16px" }}>
              Unsubscribe
            </Typography>
          </Button>
        </form>
      </Box>
    </Paper>
  );
};

export default UnsubscribeForm;
