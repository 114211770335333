import React, { useState, useEffect } from "react";
import { Box, Skeleton, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import useStyles from "./Styles";
import MediaCart from "./MediaCart";
import ProductCart from "./ProductCart";
import VoucherCart from "./VoucherCart";
import YouMayAlsoLike from "./YouMayAlsoLike";
import {
  useGetCartData,
  useGetMediaCartData,
} from "../../Hooks/ProductActions/useGetCartData";
import sendEvents from "../../utils/sendEvents.js";
import TostMessagesTop from "../../Component/OTPToast";
import { useNavigate, useLocation } from "react-router-dom";
import { get_Cart_Items } from "../../redux/action/CartActions";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { useCreatePrchaseOrder } from "../../Hooks/OrderActions/useCreatePrchaseOrder";
import { useGetAllCartVouchers } from "../../Hooks/VoucherActions/useGetAllCartVouchers";
const CartPage = () => {
  const dispatch = useDispatch();
  const Location = useLocation();
  const [balance, setBalance] = React.useState(null);
  const [ToastNoti, setToastNoti] = useState();
  const [loadingCart, setLoadingCart] = useState(true);

  // get Logged data here
  const { data: datahere } = useGetLoggedInUser();
  //  get cart item here
  const { data: cartItems, refetch: cartRefetch } = useGetCartData();
  //  get media cart here
  const { data: MediaCartItems, refetch: mediaCartRefetch } =
    useGetMediaCartData();
  const classes = useStyles();

  // appear Toast when user on cart page
  async function GetToast() {
    await axios
      .get("ToastRoute/Get_toast")
      .then((response) => {
        const data = response.data;
        let finaltoast = data.find((res) => res?.Page === "Cart");
        const CompanyId = ToastNoti?.CompanyId;
        setToastNoti(finaltoast);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  }

  useEffect(() => {
    GetToast();
  }, []);

  //  get Voucher cart here
  const {
    data: VoucherCartData,
    isLoading: VoucherCartLoading,
    error: VoucherDataError,
  } = useGetAllCartVouchers();

  // wallet api here
  const reqBalance = async () => {
    await axios
      .get("wallet/mywallet", {
        withCredentials: true,
      })
      .then((res) => {
        setBalance(res?.data?.data?.balance);
      });
  };

  // Toast Notification postion and called every time
  useEffect(() => {
    toast.info(ToastNoti?.Message, {
      position: "top-center",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);

  useEffect(() => {
    reqBalance();
  }, []);

  // send created purchase order
  const { data: mutatedata, mutate: createOrder } = useCreatePrchaseOrder();

  useEffect(() => {
    if (mutatedata?.data === "OrderSummary validation failed") {
      toast.error("OrderSummary validation failed");
    }
  }, [mutatedata]);

  const cartProductIds =
    cartItems &&
    cartItems?.length > 0 &&
    cartItems?.map((item) => item?.ProductId?.id);

  useEffect(() => {
    dispatch(get_Cart_Items());
  }, []);

  const [activePanel, setActivePanel] = useState(
    Location?.state ? Location?.state : 0
  );

  const switchPanel = (panelIndex) => {
    setActivePanel(panelIndex);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingCart(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        borderRadius: "0px",
        boxShadow: "none",
        width: "100%",
      }}
      elevation={0}
    >
      <BreadCrumbHeader MainText="Cart" showbreadcrumb={true} />

      {loadingCart ? (
        <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
          <Box
            sx={{
              width: "100%",
              mx: "auto",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <SkeltonRectSmall />
            <SkeltonRectSmall />
            <SkeltonRectSmall />
          </Box>
          <SkeletonRectangle />
          <SkeletonRectangle />
          <SkeletonRectangle />
          <SkeletonRectangle />
          <SkeletonRectangle />
          <SkeletonRectangle />
        </div>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              height: "35px",
              bgcolor: "teransparent",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <button
              onClick={() => {
                switchPanel(0);
                sendEvents("Click on Product cart");
              }}
              style={{
                borderRadius: "10px 0px 0px 10px",
              }}
              className={
                activePanel === 0
                  ? classes.activeButton
                  : classes.unActiveButton
              }
            >
              {cartItems?.length
                ? `Product Cart ( ${cartItems?.length} )`
                : "Product Cart"}
            </button>
            <button
              onClick={() => {
                switchPanel(1);
                sendEvents("Click on Media cart");
              }}
              className={
                activePanel === 1
                  ? classes.activeButton
                  : classes.unActiveButton
              }
            >
              {MediaCartItems?.body?.length
                ? "Media Cart ( " + MediaCartItems?.body?.length + " )"
                : "Media Cart"}
            </button>
            <button
              style={{
                borderRadius: "0px 10px 10px 0px",
              }}
              onClick={() => {
                switchPanel(2);
                sendEvents("Click on Voucher cart");
              }}
              className={
                activePanel === 2
                  ? classes.activeButton
                  : classes.unActiveButton
              }
            >
              {VoucherCartData?.data?.length
                ? "Voucher Cart ( " + VoucherCartData?.data?.length + " )"
                : "Voucher Cart"}
            </button>
          </Box>

          {activePanel === 0 && <ProductCart />}

          {activePanel === 1 && <MediaCart />}

          {activePanel === 2 && <VoucherCart />}

          {cartProductIds && <YouMayAlsoLike productIds={cartProductIds} />}
        </>
      )}

      <TostMessagesTop
        PageContent="Note to check in the correct cart!"
        PageCompanyName={datahere?.data?.companyName}
      />
    </Paper>
  );
};

export default CartPage;

const SkeltonRectSmall = () => {
  return (
    <>
      <Skeleton
        variant="rectangular"
        height={50}
        width={"50%"}
        animation="wave"
        sx={{ borderRadius: "10px", mt: 3, width: "100%" }}
      />
    </>
  );
};

const SkeletonRectangle = () => {
  return (
    <>
      <Box sx={{ width: "100%", mx: "auto" }}>
        <Skeleton
          variant="rectangular"
          height={110}
          width={"100%"}
          animation="wave"
          sx={{ borderRadius: "10px", mt: 3, width: "100%" }}
        />
      </Box>
    </>
  );
};
