import BreadCrumbHeader from "../../../../components/Header/BreadCrumbHeader.jsx";
import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Typography,
  Button,
  Container,
  FormControl,
} from "@mui/material";
import { useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DotLoader from "../../../../components/LoadingButton/DotLoader.js";
import GlobalToast from "../../../../components/Toasts/GlobalToast.jsx";

const LifestyleBulkUploadPage = () => {
  const [upload, setUpload] = useState(null);
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storeCompanyType, setStoreCompanyType] = useState("");
  const [category, setCategory] = useState(storeCompanyType);
  const [storeStoredFiles, setStoreStoredFiles] = useState([]);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const myForm = new FormData();
    myForm.append("file", file);

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      setUpload(data);
    };

    reader.onerror = (err) => {
      console.error("Error reading the file:", err);
    };
    reader?.readAsArrayBuffer(file);
    uploadExcelFile(file);
  };

  const uploadExcelFile = async (myForm) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", myForm);
      const categoryName =
        category === "Others"
          ? `Other Products Output File`
          : `${category} Products Output File`;
      // console.log("formData", categoryName);
      formData.append("data", categoryName);
      const { data } = await axios.post(
        "publiq_bulk_upload/bulk_upload",
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
          withCredentials: true,
        }
      );
      if (data?.success === true) {
        toast.success("File uploaded successfully", {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setData(data?.success);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.name, {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Network Error:", error.message);
      }
    }
  };

  const findAllWebHookReponsesAndFiles = async () => {
    try {
      await axios
        .get(`publiq_bulk_upload/findAll_BulkUploadResponseAnd_NonStoreFiles`)
        .then((response) => {
          setStoreStoredFiles(response.data?.data);
        })
        .catch((error) => {
          console.error("Error fetching webhooks and files:", error.message);
        });
    } catch (error) {
      console;
    }
  };

  const confirmedUploadTheFile = async (Unique_id) => {
    try {
      await axios.post(
        `publiq_bulk_upload/upload_bulkUpload_files_lifestyle`,
        {
          data: Unique_id,
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      GlobalToast(error.response.data.message, "error");
      console.error("Error uploading the file:", error);
    }
  };

  useEffect(() => {
    const companyType = localStorage.getItem("companyType");
    setStoreCompanyType(companyType);
    setCategory(companyType);
    findAllWebHookReponsesAndFiles();
  }, []);

  return (
    <>
      <BreadCrumbHeader title="Bulk Upload" MainText={"Bulk Upload"} />
      <Paper
        sx={{
          width: "100%",
          height: "70vh",
          position: "relative",
          display: "flex",
        }}
        elevation={0}
      >
        <Box
          sx={{
            width: "40%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data ? (
            <Container
              sx={{
                display: "grid",
                width: "100%",
                maxWidth: "100%",
                textAlign: "center",
                gap: "15px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#444444",
                  fontFamily: "Poppins",
                  marginTop: "24px",
                  fontFamily: "Poppins",
                  display: "grid",
                  gap: "15px",
                }}
              >
                Please wait for some times while we go through your uploaded
                file. <DotLoader />
              </Typography>
            </Container>
          ) : (
            <Container
              sx={{
                display: "grid",
                width: "700px",
                maxWidth: "700px",
                textAlign: "center",
                gap: "15px",
              }}
            >
              <Typography variant="h4" gutterBottom>
                Upload Excel File
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#444444",
                    fontFamily: "Poppins",
                  }}
                >
                  Select Category
                </Typography>
                <FormControl
                  fullWidth
                  margin="normal"
                  sx={{ maxWidth: "400px" }}
                >
                  <select
                    labelId="category-label"
                    value={category}
                    label="Category"
                    onChange={handleCategoryChange}
                    style={{
                      border: "1px solid lightgray",
                      height: "40px",
                      width: "100%",
                      padding: "10px",
                    }}
                  >
                    {storeCompanyType ? (
                      <option
                        value={storeCompanyType}
                        style={{
                          padding: "10px",
                          height: "35px",
                          width: "100%",
                        }}
                      >
                        <Typography variant="h6">{storeCompanyType}</Typography>
                      </option>
                    ) : null}
                  </select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 500,
                    color: "#444444",
                    fontFamily: "Poppins",
                  }}
                >
                  Choose you Excel File{" "}
                </Typography>
                <input
                  type="file"
                  ref={inputFile}
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                />
                <Button
                  variant="contained"
                  onClick={onButtonClick}
                  sx={{
                    height: "40px",
                    fontSize: "14px",
                    textTransform: "none",
                    width: "400px",
                    background: "#c64091",
                    "&:hover": {
                      background: "#fff",
                      color: "#c64091",
                      cursor: "pointer",
                      border: "1px solid #c64091",
                    },
                  }}
                >
                  Add file <FileUploadIcon fontSize="large" />
                </Button>
              </Box>
            </Container>
          )}
        </Box>
        <Box
          sx={{
            borderLeft: "1px solid #c64091",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "60%",
          }}
        >
          <Box
            sx={{
              mx: "auto",
              width: "80%",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 500,
              }}
            >
              Recent Uploaded {category} Files
            </Typography>
            <Box
              sx={{
                maxHeight: "400px",
                overflowY: "scroll",
              }}
            >
              {storeStoredFiles?.map((res, idx) => {
                if (res?.data?.Link) {
                  return (
                    <Box
                      key={idx}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          width: "50%",
                        }}
                      >
                        {res?.data?.Link}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={() =>
                        //   confirmedUploadTheFile(res?.data?.Unique_id)
                        // }
                        sx={{
                          height: "40px",
                          fontSize: "14px",
                          textTransform: "none",
                          width: "150px",
                          // width: "auto",
                          background: "#c64091",
                          "&:hover": {
                            background: "#fff",
                            color: "#c64091",
                            cursor: "pointer",
                            border: "1px solid #c64091",
                          },
                        }}
                      >
                        Upload Products
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        href={res?.data?.Link}
                        sx={{
                          height: "40px",
                          fontSize: "14px",
                          textTransform: "none",
                          width: "150px",
                          // width: "auto",
                          background: "#fff",
                          color: "#c64091",
                          border: "1px solid #c64091",
                          "&:hover": {
                            background: "#c64091",
                            color: "#fff",
                            cursor: "pointer",
                            border: "1px solid #c64091",
                          },
                        }}
                      >
                        Download File
                      </Button>
                    </Box>
                  );
                }
              })}
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default LifestyleBulkUploadPage;
const sellerBoxDesign = {
  width: "82px",
  height: "84px",
  position: "absolute",
  top: "-6px",
  boxShadow: "0px -5px 5px 0px rgba(0, 0, 0, 0.2)",
  left: "-2px",
  borderRadius: "20px",
  display: "flex",
  marginTop: "0px",
  // border:"1px solid black"
};

const noteText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "28px",
  display: "flex",
  alignItems: "center",
  marginTop: "3rem",
  color: "#6B7A99",
  width: "80%",
};
const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1.2rem",
  color: "#929EAE",
};
const rectangleStyle = {
  width: "300px", // Set your desired width
  height: "70px", // Set your desired height
  backgroundColor: "white",
  borderRadius: "10px", // Set your desired border radius
  position: "relative",
  boxShadow:
    "0px -5px 5px 0px rgba(0, 0, 0, 0.2), 5px 0px 5px 0px rgba(0, 0, 0, 0.2)", // Add upper and right shadows
};

const filterbutton = {
  // color: "#1B212D",
  // border: "1px solid #F5F5F5",
  // borderRadius: "10px",
  width: " 281px",
  height: "48px",
  ml: "auto",
  background: "#445FD2",
  borderRedius: "10px",
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  textTransform: "none",
};

const Upload = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "18px",
  alignItems: "center",
  textAlign: "center",
  textDecoration: "none",
  color: "#445FD2",
  cursor: "pointer",
};
