import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch wallet data
export const fetchWalletData = createAsyncThunk(
  "wallet/fetchWalletData",
  async ({ currentPage = 1, userData, searchData }, { rejectWithValue }) => {
    try {
      // Fetch wallet data with pagination
      const res = await axios.post(
        `wallet/get_user_wallet_statement?page=${currentPage}`,
        { User: userData, searchData: searchData }
      );

      // Fetch wallet data without limit
      const resNoLimit = await axios.post(
        "wallet/get_user_wallet_noLimit_statement",
        {
          User: userData,
        }
      );

      return {
        transactionsData: res?.data?.transactionsData,
        finalCount: res?.data?.finalCount,
        statementNoLimit: resNoLimit?.data,
      };
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch wallet data"
      );
    }
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    statement: [],
    statementNoLimit: [],
    finalCount: 0,
    currentPage: 1,
    searchData: "",
    loading: false,
    error: null,
  },
  reducers: {
    setCurrentPage: (state, action) => {
      if (typeof action.payload === "number") {
        state.currentPage = action.payload;
      } else {
        console.error("Invalid currentPage value:", action.payload);
      }
    },
    setSearchData(state, action) {
      state.searchData = action.payload; // Update search data
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWalletData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWalletData.fulfilled, (state, action) => {
        state.loading = false;
        state.statement = action.payload.transactionsData;
        state.finalCount = action.payload.finalCount;
        state.statementNoLimit = action.payload.statementNoLimit;
      })
      .addCase(fetchWalletData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setCurrentPage, setSearchData } = walletSlice.actions;
export default walletSlice.reducer;
