import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import stackofcoin from "../../assets/CartPage/unnamed 1.svg";
import CompanyName from "../../components/CompanyName";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import { listPurchaseOrders } from "../../redux/action/PurchaseOrder_Action";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetAllBuyerApprovedOrdersForSeller,
  useGetAllMediaBuyerApprovedOrdersForSeller,
} from "../../Hooks/OrderActions/useGetAllBuyerApprovedOrdersForSeller";
import addItemCartIcon from "../../assets/CartPage/addItemIcon.svg";
import Skeleton from "@mui/material/Skeleton";
import { BiCheckbox } from "react-icons/bi";
import { useGetSellerSidePendingOrder } from "../../Hooks/OrderActions/useGetSellerSidePendingOrder";
import AcceptedIcon from "../../assets/Images/CommonImages/Accepted.png";
import DownIcon from "../../assets/Images/CommonImages/Down.png";
import GoLeft from "../../assets/Images/CommonImages/GoLeft.png";
import PendingIcon from "../../assets/Images/CommonImages/Pending.png";
import RejectedIcon from "../../assets/Images/CommonImages/Rejected.png";
import stackofcoins from "../../assets/Stack of Coins.svg";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import CommaSeprator from "../../components/CommaSeprator";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import ImageAndProductCarousel from "../../components/Carousel/ImageAndProductCarousel";

function MediaPIList() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Order, setOrder] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(null);
  const [Allselected, setAllselected] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [AllOrders, setAllOrders] = useState();

  const [ProformaInvoiceData, setProformaInvoiceData] = useState();

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const {
    data: purchaseOrderDataSeller,
    isLoading: purchaseOrderLoadingSellerPending,
    refetch: refetchbuyerpurchaseorder,
  } = useGetAllMediaBuyerApprovedOrdersForSeller(currentPage);

  const fetchAllMediaPI = async (currentPage) => {
    try {
      await axios
        .get(
          `order/get_purchase_order_for_buyer_waiting_for_pi?page=${currentPage}`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          setProformaInvoiceData(response?.data);
          setInitialPaginationCount(response?.data?.finalCount);
        });
    } catch (error) {}
  };

  const {
    data: purchaseOrderDataSellerAfterAcceptOrReject,
    isLoading: purchaseOrderLoadingSellerPendingAfterAcceptOrReject,
    refetch: refetchBuyerAfterAcceptOrReject,
  } = useGetSellerSidePendingOrder(currentPage);

  useEffect(() => {
    refetchbuyerpurchaseorder({ page: currentPage });
  }, [currentPage]);

  useEffect(() => {
    dispatch(listPurchaseOrders());
  }, [dispatch]);

  let dataId = [];

  Order.map((el) => {
    dataId.push(el._id);
  });

  const GetOrdersId = async () => {
    return await axios
      .get(`order/get_all_orders_id`)
      .then((res) => {
        setAllOrders(res?.data?.orders);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    GetOrdersId();
    fetchAllMediaPI(currentPage);
  }, []);

  useEffect(() => {
    fetchAllMediaPI(currentPage);
  }, [currentPage]);

  return (
    <Paper
      sx={{
        boxShadow: "none",
        background: "transparent",
        width: "95%",
        mx: "auto",
      }}
      elevation={0}
    >
      <BreadCrumbHeader
        MainText={"Media PI (Purchase)"}
        showbreadcrumb={true}
      />
      <Grid
        container
        sx={{
          background: "#FFFFFF",
          padding: "1rem",
          borderRadius: "20px",
          minHeight: "70vh",
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: 1,
          position: "relative",
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            height: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "170px",
            }}
          >
            <Link to={"/home"}>
              <img src={GoLeft} width="22px" alt="golefticon" />
            </Link>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            sx={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "99%",
            }}
          >
            <Grid
              item
              xl={0.5}
              lg={0.5}
              md={0.5}
              sm={0.5}
              xs={0.5}
              sx={{
                textAlign: "center",
              }}
            >
              <Typography sx={tableheading}>No</Typography>
            </Grid>
            <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
              <Typography sx={tableheading}>Media Name</Typography>
            </Grid>
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
              <Typography align="center" sx={tableheading}>
                Order Id
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Total Products
              </Typography>
            </Grid>
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
              <Typography align="center" sx={tableheading}>
                Invoice Total
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Order date
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Partial
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <Typography align="center" sx={tableheading}>
                Status
              </Typography>
            </Grid>

            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Typography align="center" sx={tableheading}>
                Action
              </Typography>
            </Grid>
          </Grid>
          <React.Fragment>
            {ProformaInvoiceData?.OrderSumm?.length > 0 &&
              ProformaInvoiceData?.OrderSumm?.map((data, idx) => {
                let totalPrice = 0;
                data?.PurchaseOrderData?.ProductData?.map((data) => {
                  totalPrice +=
                    data.DiscountedPrice *
                      data?.TimelineToBought *
                      data.ProductQuantity *
                      data?.BoughtSeconds ||
                    data.DiscountedPrice * data.ProductQuantity;
                  return totalPrice;
                });

                function convertDate(inputFormat) {
                  function pad(s) {
                    return s < 10 ? "0" + s : s;
                  }
                  var d = new Date(inputFormat);
                  return [
                    pad(d.getDate()),
                    pad(d.getMonth() + 1),
                    d.getFullYear(),
                  ].join("/");
                }

                const OrderIdData = AllOrders?.filter((el) => {
                  return el?.PurchaseOrderId === data?._id;
                });

                if (purchaseOrderLoadingSellerPending) {
                  return (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={35}
                    />
                  );
                } else {
                  return (
                    <Accordion
                      sx={{
                        p: 0,
                        boxShadow: "none",
                        border: "none",
                        borderBottom:
                          data?.PartialOrderDetails?.length > 0
                            ? "1px solid red"
                            : "1px solid #F5F5F5",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <img
                            src={DownIcon}
                            style={{ width: "9px" }}
                            alt="downicon"
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ p: 0 }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xl={0.5}
                            lg={0.5}
                            md={0.5}
                            sm={0.5}
                            xs={0.5}
                            sx={{
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {" "}
                              {idx + 1}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={2.5}
                            lg={2.5}
                            md={2.5}
                            sm={2.5}
                            xs={2.5}
                          >
                            <ImageAndProductCarousel
                              carouselData={
                                data?.PurchaseOrderData?.ProductData
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {data?.OrderId}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {data?.PurchaseOrderData?.ProductData?.length}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              {/* <img
                                src={stackofcoin}
                                alt="rupieicon"
                                style={{
                                  width: "15px",
                                  height: "auto",
                                }}
                              /> */}
                              <CommaSeprator Price={totalPrice} />
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography sx={TableBodyTextStyling}>
                              {convertDate(data?.PoDate)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontFamily: "Poppins" }}
                            >
                              {data?.PurchaseOrderData?.PartialOrderDetails
                                ?.length > 0
                                ? "Yes"
                                : "No"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBodyTextStyling,
                                fontSize: "14px",
                                color:
                                  data?.PurchaseOrderData?.SellerOrderStatus ===
                                  "Pending"
                                    ? "#FFB600"
                                    : data?.PurchaseOrderData
                                        ?.SellerOrderStatus === "Accepted"
                                    ? "#118A2C"
                                    : "#FF0000",

                                mx: "auto",
                              }}
                            >
                              {data?.PurchaseOrderData?.SellerOrderStatus}
                            </Typography>
                            {data?.PurchaseOrderData?.SellerOrderStatus ===
                            "Pending" ? (
                              <Box
                                component="img"
                                src={PendingIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            ) : data?.PurchaseOrderData?.SellerOrderStatus ===
                              "Accepted" ? (
                              <Box
                                component="img"
                                src={AcceptedIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            ) : (
                              <Box
                                component="img"
                                src={RejectedIcon}
                                sx={{ width: "13px", height: "13px" }}
                              />
                            )}
                          </Grid>

                          <Grid
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            sx={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {data?.PurchaseOrderData?.SellerOrderStatus ===
                            "Pending" ? (
                              <Box
                                onClick={() => {
                                  setOpenSecond(true);
                                }}
                                sx={{
                                  cursor: "pointer",
                                  width: "100px",
                                  mx: "auto",
                                  height: "30px",
                                  background: "#2261a2",
                                  borderRadius: "4.39877px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignContent: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...TableBodyTextStyling,
                                    color: "#fff",
                                  }}
                                >
                                  Next Step
                                </Typography>
                              </Box>
                            ) : data?.PurchaseOrderData?.SellerOrderStatus ===
                              "Accepted" ? (
                              <Box
                                onClick={() => {
                                  navigate(
                                    `/home/performainvoice/${data?.PurchaseOrderData?._id}`
                                  );
                                }}
                                sx={{
                                  cursor: "pointer",
                                  width: "100px",
                                  mx: "auto",
                                  height: "30px",
                                  background: "#2261a2",
                                  borderRadius: "4.39877px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  alignContent: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    ...TableBodyTextStyling,
                                    color: "#fff",
                                  }}
                                >
                                  Next Step
                                </Typography>
                              </Box>
                            ) : (
                              "--"
                            )}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0, pb: 2 }}>
                        <Box sx={{ ml: "50px" }}>
                          <CompanyName
                            CompanyId={data?.PurchaseOrderData?.SellerCompanyId}
                            CompanyName={
                              data?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }
                            CompanyTypeName={
                              data?.PurchaseOrderData?.SellerDetails
                                ?.CompanyTypeName
                            }
                          />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
          </React.Fragment>
          <Dialog
            open={openSecond}
            onClose={() => {
              setOpenSecond(false);
            }}
            sx={{
              backdropFilter: "blur(2px)",
            }}
            fullWidth
            maxWidth="md"
            PaperProps={{
              sx: {
                width: "30%",
                height: "auto",
                minHeight: "180px",
                maxHeight: "180px",
                borderRadius: "20px",
                justifyContent: "center",
              },
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "90%",
                  }}
                >
                  <CloseIcon
                    sx={{
                      color: "#c6cad2",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (openSecond === false) {
                        setOpenSecond(true);
                      } else {
                        setOpenSecond(false);
                      }
                    }}
                  />
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "20px",
                      lineHeight: "28px",
                      textAlign: "center",
                      color: "#6B7A99",
                    }}
                  >
                    Thank you for your Purchase Order. You will be notified as
                    soon as the Seller raises the Proforma Invoice. Your
                    patience is appreciated.
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
        <Stack spacing={2}>
          <StyledPagination
            count={initialPaginationCount}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
}

export default MediaPIList;

const TableBodyTextStyling = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#1B212D",
};

const tableheading = {
  fontFamily: "Poppins",
  color: "#7E8BA6",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  textTransform: "Capitalize",
};
