import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdatePurchaseOrder } from "../../Hooks/OrderActions/useUpdatePurchaseOrder";
import DocDownloadImg from "../../assets/Images/CommonImages/DocDownload.png";
import LeftArrowIcon from "../../assets/Images/CommonImages/GoLeft.png";
import PrintPurchaseOrder from "../../assets/Images/CommonImages/Print.png";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import BxiCoin from "../../assets/Images/CommonImages/BXIToken.svg";
import { getCompanyById } from "../../redux/action/CompanyActions";
import { getOrderSummary } from "../../redux/action/OrderSummaryActions";
import StateData from "../../utils/StateCityArray.json";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { useGetInvoiceByOrderSummary } from "../../Hooks/Invoices/useGetInvoiceByOrderSummary";
import CommaSeprator from "../../components/CommaSeprator";
import NumberToWord from "../../components/NumberToWord";
import PageLoader from "../../components/LoadingButton/PageLoader";

const SellerProformaInvoiceDetails = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [stateArray, setStateArray] = useState();
  const [Address, setAddress] = useState("");
  const [Area, setArea] = useState("");
  const [state, setState] = useState("");
  const [BuyerShippingAddress, setBuyerShippingAddress] = useState();
  const [CityArray, setCityArray] = useState();
  const [checked, setChecked] = useState(false);

  const [MemberShipData, setMemberShipData] = useState();
  const [StoreOrderId, setStoreOrderId] = useState();
  const [SellerWebsiteData, setSellerWebsiteData] = useState();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setBuyerShippingAddress({
      PinCode: pincode,
      City: city,
      State: state,
      Address: Address,
    });
  }, [pincode, city, state, Address, Area]);

  const { OrderSummary: InvoiceDatasss, loading: InvoiceDataLoading } =
    useSelector((state) => state.OrderSummaryD);

  useEffect(() => {
    dispatch(getCompanyById(InvoiceData?.SellerCompanyId));
    dispatch(getOrderSummary(id));
  }, [dispatch, id]);

  const {
    data: InvoiceData,
    loading: InvoiceLoading,
    refetch: refetchInvoiceData,
  } = useGetInvoiceByOrderSummary(InvoiceDatasss?._id);

  const GetCompanyWebsiteByID = async () => {
    try {
      const response = await axios.get(
        `company/get_company/${InvoiceData?.SellerDetails?.SellerCompanyId}`,
        {
          withCredentials: true,
        }
      );
      setSellerWebsiteData(response?.data?.website);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetCompanyWebsiteByID();
  }, [InvoiceData]);

  useEffect(() => {
    refetchInvoiceData();
  }, [InvoiceData]);

  let BuyerId = InvoiceData?.BuyerDetails?.BuyerCompanyId;

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(`/company/get_company/${BuyerId}`, {
        withCredentials: true,
      });
      const data =
        response.data.memberships[response?.data?.memberships?.length - 1]
          .MembershipPlan;
      setMemberShipData(data);
    } catch (error) {}
  };

  useEffect(() => {
    GetCompanyByID();
  }, [InvoiceData]);

  async function GetOrderByInvoice() {
    await axios.get(`order/get_order_by_orderSummaryId/${id}`).then((res) => {
      setStoreOrderId(res?.data?.PurchaseOrderData);
    });
  }
  useEffect(() => {
    GetOrderByInvoice();
  }, []);

  let BxiCommision = StoreOrderId?.CouponData?.BXICommission
    ? Number(StoreOrderId?.CouponData?.BXICommission)
    : Number(MemberShipData?.GST);

  const ChargesArray = [
    {
      name: "Packaging charges",
      gst: Number(InvoiceData?.PackagingData?.packagingcostgstfee),
      hsn: "998540",
      taxableValue: Number(InvoiceData?.PackagingData?.packagingcosts),
      totalTaxAmount:
        (Number(InvoiceData?.PackagingData?.packagingcosts) *
          Number(InvoiceData?.PackagingData?.packagingcostgstfee)) /
        100,
    },
    {
      name: "Logistics Charges",
      gst: Number(InvoiceData?.Transportationdata?.gstFee || 0),
      hsn: "996519",
      taxableValue: Number(
        InvoiceData?.Transportationdata?.transportationfee || 0
      ),
      totalTaxAmount:
        (Number(InvoiceData?.Transportationdata?.transportationfee || 0) *
          Number(InvoiceData?.Transportationdata?.gstFee || 0)) /
        100,
    },
    {
      name: "BXI Commission",
      gst: 18,
      commission: BxiCommision,
      hsn: "996211",
      taxableValue:
        (Number(InvoiceData?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
        100,
      totalTaxAmount:
        (((Number(InvoiceData?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
          100) *
          18) /
        100,
    },
  ];

  let storeDataIds = [];
  let TotalQuantity = 0;
  let totalAmount = 0;
  let totalPricePerUnit = 0;
  let totatlTaxableAmount = 0;
  let totalGST = 0;
  let totalAmountWithGST = 0;
  let totalAmountWithTax = 0;
  let totaltaxvalue = 0;
  let totalCGSTAmount = 0;
  let totalIGSTPercentage = 0;
  let totalSGSTAmount = 0;
  let totalSGSTPercentage = 0;
  let totalCSTPerCentage = 0;
  let TotalGSTAmount = 0;
  let TotalTokanAmount = 0;
  let Totalcount = 0;
  let ChargesTaxableValue = 0;
  let ChargesTaxableAmount = 0;

  let totaladditionalcostinrupee = 0;
  let totaladditionalcostinbxi = 0;
  InvoiceData?.ProductData?.map((item) => {
    let TotalSec = item?.BoughtSeconds * 10;
    storeDataIds.push(item);
    TotalQuantity += item.ProductQuantity;
    totalAmount +=
      InvoiceData?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item.PricePerUnitWithoutGST * item.ProductQuantity;
    totalPricePerUnit += item.PricePerUnitWithoutGST;
    TotalTokanAmount +=
      InvoiceData?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item?.PriceWithoutGST;

    totatlTaxableAmount +=
      InvoiceData?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item.PricePerUnitWithoutGST * item.ProductQuantity;
    totalGST += item.GST;
    totalAmountWithGST += (
      item?.DiscountedPrice *
      item?.ProductQuantity *
      (item?.GST / 100)
    )?.toFixed(2);
    totalAmountWithTax +=
      InvoiceData?.ProductTypeName === "Media"
        ? item?.DiscountedPrice *
            item?.ProductQuantity *
            item?.TimelineToBought *
            item?.BoughtSeconds *
            (item?.GST / 100) +
          item?.DiscountedPrice *
            item?.ProductQuantity *
            item?.TimelineToBought *
            item?.BoughtSeconds
        : item?.TotalPriceWithGSTInRupee;

    totalCGSTAmount +=
      (item?.DiscountedPrice * item?.ProductQuantity * item?.GST) / 2 / 100;
    totalSGSTAmount +=
      (item?.DiscountedPrice * item?.ProductQuantity * item?.GST) / 2 / 100;
    totalSGSTPercentage += item.GST / 2;
    totalCSTPerCentage += item.GST / 2;
    totalIGSTPercentage += item.GST;
    TotalGSTAmount += item?.TotalGSTInBXI;
    totaltaxvalue += item?.TotalGSTInBXI;
  });

  const {
    data: updatePurchaseOrderData,
    isLoading: updatePurchaseOrderLoading,
    error: updatePurchaseOrderError,
    mutate: updatePurchaseOrderMutate,
  } = useUpdatePurchaseOrder();

  const Mail_To_Send = async (props) => {
    try {
      await axios
        .put("communcations/updateCompanyCommunications", {
          template: props,
        })
        .then((response) => {});
    } catch (error) {}
  };

  useEffect(() => {
    if (updatePurchaseOrderData?.status === "Accepted") {
      toast.success("Order Accepted", {
        position: "top-center",
        autoClose: 2000,
      });
      Mail_To_Send("po-approved");
      setTimeout(() => {
        navigate("/home/productpilist");
      }, 2000);
    } else if (updatePurchaseOrderData?.status === "Rejected") {
      toast.error("Order Declined", {
        position: "top-center",
        autoClose: 2000,
      });
      Mail_To_Send("po-rejected");
      setTimeout(() => {
        navigate("/home/purchaseorderlist");
      }, 2000);
    }
  }, [dispatch, updatePurchaseOrderData]);

  async function mutatePurchaseOrderRejected() {
    await updatePurchaseOrderMutate({
      status: "Rejected",
      OrderSummaryId: id,
      BuyerShippingAddress: "",
    });
  }

  const printRef = useRef();

  const handlePrintClick = () => {
    window.print(printRef.current.innerHTML);
  };

  const downloadRef = useRef(null);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (stateArray) {
      let stateData = StateData?.filter((item) => item?.name === stateArray);
      setCityArray(stateData[0]?.data);
    }
  }, [stateArray]);
  let AdCostInrTotal = 0;
  let AdCostBxiTotal = 0;
  let AdcostTotalTaxableAmount = 0;

  InvoiceData?.ProductData?.map((item, index) => {
    if (item?.AdditionCostArray?.length > 0) {
      AdcostTotalTaxableAmount += item?.TotalTaxableAmount;
      item?.AdditionCostArray.forEach((item, index) => {
        if (item?.currencyType === "₹") {
          AdCostInrTotal += item?.GstPrice;
          totaladditionalcostinrupee += item?.TotalWithGst;
        } else if (item?.currencyType === "BXITokens") {
          AdCostBxiTotal += item?.GstPrice;
          totaladditionalcostinbxi += item?.TotalWithGst;
        }
      });
    }
  });

  InvoiceData?.ProductData?.map((item, index) => {
    item?.AdditionCost?.AdditionCostArray?.forEach((item, index) => {
      AdcostTotalTaxableAmount += item?.TotalTaxableAmount;
    });
  });

  ChargesArray?.map((res, idx) => {
    ChargesTaxableValue += Number(res?.taxableValue);
    ChargesTaxableAmount += Number(res?.totalTaxAmount);
  });

  let amountbeforeTax =
    ChargesTaxableValue + totatlTaxableAmount + AdcostTotalTaxableAmount;

  let totalTaxAmount =
    InvoiceData?.PITotals?.TotalGstAmount +
    InvoiceData?.PITotals?.TotalAdCostGstAmount +
    ChargesTaxableAmount;

  let TDS = StoreOrderId?.TDS_PERCENT ?? 1;

  let invoiceTotalAmount = totalTaxAmount + amountbeforeTax;

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  if (InvoiceLoading) {
    return <PageLoader />;
  } else {
    return (
      <Box
        sx={{
          bgcolor: "transparent",
          width: "100%",
          height: "100%",
        }}
        elevation={0}
      >
        <BreadCrumbHeader
          MainText={"Proforma Invoice"}
          PageName={"Proforma Invoice"}
          showbreadcrumb={true}
        />
        <Box
          sx={{
            width: "100%",
            bgcolor: "white",
            mx: "auto",
            borderRadius: "17px",
            pb: "40px",
          }}
          elevation={1}
        >
          <Paper
            sx={{
              width: "95%",
              mx: "auto",
              height: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
            }}
            elevation={0}
          >
            <Box
              component="img"
              src={LeftArrowIcon}
              sx={{ width: "25px", cursor: "pointer" }}
              onClick={() => navigate("/home/purchaseorderlist")}
            />
            <Box
              sx={{
                display: "flex",
                width: "60px",
                justifyContent: "space-between",
              }}
            >
              <Box
                component="img"
                src={PrintPurchaseOrder}
                sx={{ width: "22px", height: "auto", cursor: "pointer" }}
                onClick={handlePrint}
              />
              <Box
                component="img"
                src={DocDownloadImg}
                sx={{ width: "21px", height: "auto", cursor: "pointer" }}
                onClick={handlePrint}
              />
            </Box>
          </Paper>
          <Box ref={componentRef}>
            <Box
              sx={{
                width: "95%",
                mx: "auto",
                borderLeft: "1px solid #cdcdcd",
                borderRight: "1px solid #cdcdcd",
                borderBottom: "1px solid #cdcdcd",
                borderTop: "1px solid #cdcdcd",
                px: "0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  height: "auto",
                  mx: "auto",
                }}
                ref={downloadRef}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "130px",
                    width: "30%",
                    borderLeft: "1px solid #cdcdcd",
                  }}
                >
                  <img
                    src={InvoiceData?.SellerDetails?.SellerCompanyLogo}
                    style={{ width: "100px", height: "100px" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid #cdcdcd",
                    height: "130px",
                    width: "40%",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      color: "rgba(107, 122, 153, 1)",
                      opacity: 1,
                      fontWeight: 600,
                    }}
                  >
                    {InvoiceData?.SellerDetails?.SellerCompanyName}
                  </Typography>
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      textAlign: "center",
                      fontSize: "11px",
                      color: "rgba(107, 122, 153, 1)",
                      opacity: 1,
                      fontWeight: 600,
                    }}
                  >
                    {InvoiceData?.SellerDetails?.Address?.AddressLine}
                  </Typography>
                  <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                    Telwww:{InvoiceData?.SellerDetails?.SellerCompanyContact}
                  </Typography>
                  <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                    GSTIN:{InvoiceData?.SellerDetails?.GSTIN}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    color: "#445FD2",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "130px",
                    width: "30%",
                    borderLeft: "1px solid #cdcdcd",
                  }}
                >
                  {SellerWebsiteData ? SellerWebsiteData : null}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "42px",
                  mx: "auto",
                  background: "#c64091",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  Proforma Invoice
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mx: "auto",
                  height: "40px",
                  borderRight: "1px solid #cdcdcd",
                  borderLeft: "1px solid #cdcdcd",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    borderRight: "1px solid #F3F2F3",
                    pl: 1,
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      opacity: 1,
                      color: "rgba(80, 80, 80, 1)",
                    }}
                  >
                    PI Number: {InvoiceData?.PINumber}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    pl: 1,
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      opacity: 1,
                      color: "rgba(80, 80, 80, 1)",
                    }}
                  >
                    PI Date:{" "}
                    {convertDate(
                      new Date(InvoiceData?.createdAt).toLocaleDateString()
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  mx: "auto",
                  border: "1px solid #cdcdcd",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "21px",
                    background: "#c64091",
                    borderRadius: "3px 0px 0px 3",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: "100%",
                      borderRight: "1px solid #F3F2F3",
                    }}
                  >
                    <Typography
                      sx={{
                        ...CommongTextStyle,
                        fontSize: "10px",
                        color: "white",
                      }}
                    >
                      Seller Details
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        ...CommongTextStyle,
                        color: "white",
                        fontSize: "10px",
                      }}
                    >
                      Buyer Details
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",

                    alignItems: "flex-start",

                    width: "100%",
                    py: 0,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      mx: "auto",
                      borderRight: "1px solid #CDCDCD",
                      p: 2,
                    }}
                  >
                    <Table sx={{ p: 0 }}>
                      <TableBody sx={{ p: 0 }}>
                        <TableRow sx={{ p: 0 }}>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                              width: "90px",
                            }}
                          >
                            <Typography sx={TextStyleTitle}>
                              {" "}
                              Seller Name
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTwo}>
                              {InvoiceData?.SellerDetails?.SellerCompanyName}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography sx={TextStyleTitle}>
                              {" "}
                              Address{" "}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTwo}>
                              {InvoiceData?.SellerDetails?.Address?.AddressLine}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTitle}> GSTIN </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTwo}>
                              {InvoiceData?.SellerDetails?.GSTIN}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTitle}>
                              {" "}
                              State:{" "}
                            </Typography>{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              position: "relative",
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTwo}>
                              {InvoiceData?.SellerDetails?.Address?.State}
                            </Typography>
                            <Typography
                              sx={{
                                ...TextStyleTwo,
                                textAlign: "right",
                                position: "absolute",
                                right: "0px",
                                top: "0px",
                              }}
                            >
                              Code: {InvoiceData?.SellerDetails?.StateCode}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTitle}> CUID </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTwo}>
                              {InvoiceData?.Seller_CUID}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                  <Box sx={{ width: "100%", mx: "auto", p: 2 }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                              width: "90px",
                            }}
                          >
                            <Typography sx={TextStyleTitle}>
                              {" "}
                              Buyer Name
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTwo}>
                              {" "}
                              {InvoiceData?.BuyerDetails?.BuyerCompanyName}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography sx={TextStyleTitle}>
                              {" "}
                              Address{" "}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTwo}>
                              {InvoiceData?.BuyerDetails?.Address?.AddressLine}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTitle}> GSTIN </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTwo}>
                              {InvoiceData?.BuyerDetails?.GSTIN}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTitle}> State</Typography>{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                              position: "relative",
                            }}
                          >
                            <Typography sx={TextStyleTwo}>
                              {" "}
                              {InvoiceData?.BuyerDetails?.Address?.State}
                            </Typography>{" "}
                            <Typography
                              sx={{
                                ...TextStyleTwo,
                                textAlign: "right",
                                position: "absolute",
                                right: "0px",
                                top: "0px",
                              }}
                            >
                              Code: {InvoiceData?.BuyerDetails?.StateCode}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTitle}> CUID </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0.5,
                              borderBottom: "none",
                            }}
                          >
                            <Typography sx={TextStyleTwo}>
                              {InvoiceData?.Buyer_CUID}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Box>
              {props?.SellerPage === true &&
              InvoiceData?.BuyerRequestedAddress ? (
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "21px",
                      background: "#c64091",
                      borderRadius: "3px 0px 0px 3",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        height: "100%",
                        borderRight: "1px solid #F3F2F3",
                      }}
                    >
                      <Typography
                        sx={{
                          ...CommongTextStyle,
                          fontSize: "10px",
                          color: "white",
                        }}
                      >
                        Buyer Ship To / Delivery Location Details Below
                      </Typography>
                    </Box>
                  </Box>

                  <Table
                    sx={{
                      p: 0,
                      border: "1px solid #cdcdcd",
                      borderLeft: "none",
                      borderRight: "none",
                      borderBottom: "none",
                      borderRadius: "0px 0px 10px 10px",
                      width: "100%",
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={AddressTextStyleTitle}>
                            {" "}
                            State: {InvoiceData?.BuyerRequestedAddress?.State}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={AddressTextStyleTitle}>
                            {" "}
                            City: {InvoiceData?.BuyerRequestedAddress?.City}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={AddressTextStyleTitle}>
                            {" "}
                            Pincode:{" "}
                            {InvoiceData?.BuyerRequestedAddress?.PinCode}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "auto",
                            maxWidth: "300px",
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={AddressTextStyleTitle}>
                            {" "}
                            Address:{" "}
                            {InvoiceData?.BuyerRequestedAddress?.Address}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              ) : null}
              <TableContainer
                component={Paper}
                sx={{
                  maxWidth: "100%",
                  mx: "auto",
                }}
              >
                <Table sx={{ minWidth: "700px" }} aria-label="customized table">
                  <TableHead>
                    <TableRow
                      sx={{
                        height: "42px",
                        bgcolor: "#c64091",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}> Sr. No.</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={2}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>
                          Product / Service Description
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>
                          HSN / SAC
                          <br />
                          Code
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>QTY</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>Rate</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>Amount</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography sx={TableTextStyle}>
                          Taxable Value
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {InvoiceData?.ProductData?.map((row, idx) => {
                      let TotalSec = row?.TotalSec;
                      Totalcount += 1;
                      return (
                        <React.Fragment key={idx}>
                          <TableRow
                            sx={{
                              height: "42px",
                              backgroundColor: "#F7F7F7",
                            }}
                          >
                            <TableCell
                              align="center"
                              colSpan={1}
                              rowSpan={1}
                              sx={{ borderRight: "1px solid #CDCDCD" }}
                            >
                              <Typography sx={TableBottomtext}>
                                {Totalcount}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                              colSpan={1}
                              rowSpan={1}
                              sx={{ borderRight: "1px solid #CDCDCD" }}
                            >
                              <Typography
                                sx={{
                                  ...TableBottomtext,
                                  fontSize: "14px",
                                  width: "300px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row?.ProductName}
                              </Typography>

                              {row?.TotalSec && (
                                <Typography>
                                  {row?.ProductTypeId === "MediaOffline" ||
                                  row?.ProductTypeId ===
                                    "News Papers / Magazines"
                                    ? `(${row?.TimelineToBought} ${row?.Timeline})`
                                    : `(${TotalSec} sec) (${row?.TimelineToBought} ${row?.Timeline})`}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={1}
                              rowSpan={1}
                              sx={{ borderRight: "1px solid #CDCDCD" }}
                            >
                              <Typography sx={TableBottomtext}>
                                {row?.HSN}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={1}
                              rowSpan={1}
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator Price={row?.ProductQuantity} />
                                &nbsp;
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={1}
                              rowSpan={1}
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator
                                  Price={row?.PricePerUnitWithoutGST}
                                />
                                &nbsp;
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={1}
                              rowSpan={1}
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                <CommaSeprator
                                  Price={row?.TotalPriceWithoutGST}
                                />
                                &nbsp;
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={1}
                              rowSpan={1}
                              sx={{
                                borderRight: "1px solid #CDCDCD",
                                textAlign: "right",
                                px: 0,
                              }}
                            >
                              <Typography sx={TableBottomtext}>
                                {" "}
                                <CommaSeprator
                                  Price={row?.TotalPriceWithoutGST}
                                />
                                &nbsp;
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}

                    <TableRow
                      sx={{
                        height: "25px",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}>&nbsp;</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontSize: "13px",
                            width: "300px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{
                        height: "25px",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}>&nbsp;</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontSize: "14px",
                            width: "300px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;Additional Cost
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                    </TableRow>

                    {InvoiceData?.ProductData?.map((row, idx) => {
                      let TotalSec = row?.TotalSec;
                      return (
                        <React.Fragment key={idx}>
                          {row?.AdditionCost?.AdditionCostArray?.map(
                            (res, index) => {
                              Totalcount += 1;
                              return (
                                <TableRow
                                  sx={{
                                    height: "25px",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                  key={index}
                                >
                                  <TableCell
                                    align="center"
                                    colSpan={1}
                                    rowSpan={1}
                                    sx={{
                                      borderRight: "1px solid #CDCDCD",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <Typography sx={TableBottomtext}>
                                      {Totalcount}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    colSpan={1}
                                    rowSpan={1}
                                    sx={{
                                      borderRight: "1px solid #CDCDCD",
                                      padding: 0,
                                      margin: 0,
                                      // px: 1,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...TableBottomtext,
                                        fontSize: "13px",
                                        width: "300px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {" "}
                                      &nbsp;{res?.ReasonOfCost} {"  "}({" "}
                                      {res?.AdCostApplicableOn === "PerUnit"
                                        ? "Per Unit"
                                        : "One Time"}{" "}
                                      ){" "}
                                      {res?.currencyType === "₹" ? (
                                        "₹"
                                      ) : (
                                        <img
                                          src={BxiCoin}
                                          style={{
                                            width: "20px",
                                            height: "auto",
                                          }}
                                        />
                                      )}{" "}
                                      ({row?.ProductName}){" "}
                                    </Typography>

                                    {row?.TotalSec && (
                                      <Typography>
                                        {row?.ProductTypeId ===
                                          "MediaOffline" ||
                                        row?.ProductTypeId ===
                                          "News Papers / Magazines"
                                          ? `(${row?.TimelineToBought} ${row?.Timeline})`
                                          : `(${TotalSec} sec) (${row?.TimelineToBought} ${row?.Timeline})`}
                                      </Typography>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    colSpan={1}
                                    rowSpan={1}
                                    sx={{
                                      borderRight: "1px solid #CDCDCD",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...TableBottomtext,
                                        color: "rgba(5, 5, 5, 1)",
                                        opacity: 1,
                                      }}
                                    >
                                      {res?.AdCostHSN}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    colSpan={1}
                                    rowSpan={1}
                                    sx={{
                                      borderRight: "1px solid #CDCDCD",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...TableBottomtext,
                                        color: "rgba(5, 5, 5, 1)",
                                        opacity: 1,
                                        textAlign: "right",
                                      }}
                                    >
                                      {res?.AdCostApplicableOn === "PerUnit" ? (
                                        <CommaSeprator
                                          Price={row?.ProductQuantity}
                                        />
                                      ) : (
                                        "-"
                                      )}
                                      &nbsp;
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    colSpan={1}
                                    rowSpan={1}
                                    sx={{
                                      borderRight: "1px solid #CDCDCD",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...TableBottomtext,
                                        color: "rgba(5, 5, 5, 1)",
                                        opacity: 1,
                                        textAlign: "right",
                                      }}
                                    >
                                      <CommaSeprator
                                        Price={res?.PriceWithoutGST}
                                      />
                                      &nbsp;
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    colSpan={1}
                                    rowSpan={1}
                                    sx={{
                                      borderRight: "1px solid #CDCDCD",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...TableBottomtext,
                                        color: "rgba(5, 5, 5, 1)",
                                        opacity: 1,
                                        textAlign: "right",
                                      }}
                                    >
                                      <CommaSeprator
                                        Price={res?.TotalTaxableAmount}
                                      />
                                      &nbsp;
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    colSpan={1}
                                    rowSpan={1}
                                    sx={{
                                      borderRight: "1px solid #CDCDCD",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ...TableBottomtext,
                                        color: "rgba(5, 5, 5, 1)",
                                        opacity: 1,
                                        textAlign: "right",
                                      }}
                                    >
                                      <CommaSeprator
                                        Price={res?.TotalTaxableAmount}
                                      />
                                      &nbsp;
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </React.Fragment>
                      );
                    })}

                    {ChargesArray?.map((res, idx) => {
                      Totalcount += 1;
                      return (
                        <TableRow
                          sx={{
                            height: "25px",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography sx={TableBottomtext}>
                              {Totalcount}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontSize: "13px",
                                width: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                color:
                                  res?.name === "BXI Commission"
                                    ? "red"
                                    : "#000000",
                              }}
                            >
                              &nbsp;{res?.name}{" "}
                              {res?.name === "BXI Commission"
                                ? `@ ${res?.commission}%`
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              {res?.hsn}
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                                textAlign: "right",
                              }}
                            >
                              <CommaSeprator Price={res?.taxableValue} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                                textAlign: "right",
                              }}
                            >
                              <CommaSeprator Price={res?.taxableValue} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                                textAlign: "right",
                              }}
                            >
                              <CommaSeprator Price={res?.taxableValue} />
                              &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    <TableRow
                      sx={{
                        height: "25px",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography sx={TableBottomtext}>&nbsp;</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontSize: "14px",
                            width: "300px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>

                  <TableBody>
                    <TableRow
                      sx={{
                        height: "42px",
                        backgroundColor: "#F7F7F7",
                      }}
                    >
                      <TableCell
                        align="center"
                        colSpan={2}
                        rowSpan={3}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          background: "#c64091",
                        }}
                      >
                        <Typography sx={TableTextStyle}>
                          Total amount before Tax
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>

                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        >
                          <CommaSeprator Price={TotalQuantity} />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{ borderRight: "1px solid #CDCDCD" }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        ></Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        >
                          <CommaSeprator Price={amountbeforeTax.toFixed(2)} />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          borderRight: "1px solid #CDCDCD",
                          textAlign: "right",
                          px: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            color: "rgba(5, 5, 5, 1)",
                            opacity: 1,
                          }}
                        >
                          <CommaSeprator Price={amountbeforeTax.toFixed(2)} />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>

                  <TableBody>
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderTop: "none",
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontStyle: "italic",
                          }}
                        >
                          Terms & Conditions
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      >
                        {" "}
                        <Typography sx={TableBottomtext}>
                          {" "}
                          &nbsp;&nbsp;Add: GST
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          &nbsp;&nbsp;CGST
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        {!InvoiceData?.IsIGST ? (
                          <Typography sx={TableBottomtext}>
                            <CommaSeprator
                              Price={
                                (Number(InvoiceData?.PITotals?.TotalGstAmount) +
                                  Number(
                                    InvoiceData?.PITotals?.TotalAdCostGstAmount
                                  ) +
                                  Number(ChargesTaxableAmount)) /
                                2
                              }
                            />
                          </Typography>
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderTop: "none",
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Payment 100% in advance
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      ></TableCell>
                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          &nbsp;&nbsp;SGST
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        {!InvoiceData?.IsIGST ? (
                          <Typography sx={TableBottomtext}>
                            <CommaSeprator
                              Price={
                                (Number(InvoiceData?.PITotals?.TotalGstAmount) +
                                  Number(
                                    InvoiceData?.PITotals?.TotalAdCostGstAmount
                                  ) +
                                  Number(ChargesTaxableAmount)) /
                                2
                              }
                            />
                          </Typography>
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderTop: "none",
                        }}
                      ></TableCell>

                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      ></TableCell>
                      <TableCell
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          p: 0,
                          maxWidth: "100px",
                          borderRight: "1px solid #CDCDCD",
                          mx: "auto",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          &nbsp;&nbsp;IGST
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        {InvoiceData?.IsIGST ? (
                          <Typography sx={TableBottomtext}>
                            <CommaSeprator
                              Price={
                                Number(InvoiceData?.PITotals?.TotalGstAmount) +
                                Number(
                                  InvoiceData?.PITotals?.TotalAdCostGstAmount
                                ) +
                                Number(ChargesTaxableAmount)
                              }
                            />
                          </Typography>
                        ) : null}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderBottom: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 500,
                            fontStyle: "italic",
                          }}
                        >
                          Total amount Payable (in words)
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={2}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography sx={TableBottomtext}>
                          Total GST Amount
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator Price={totalTaxAmount} />
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontWeight: 500,
                            fontStyle: "italic",
                          }}
                        >
                          {InvoiceData?.PITotals ? (
                            <NumberToWord
                              number={invoiceTotalAmount.toFixed()}
                            />
                          ) : null}
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={2}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography sx={TableBottomtext}>
                          Invoice Total Amount
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography sx={TableBottomtext}>
                          <CommaSeprator Price={invoiceTotalAmount} />
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#C64091",
                          }}
                        >
                          * Our Services are subject to TDS of{" "}
                          {StoreOrderId?.TDS_PERCENT ?? 0.1}% u/s 194-O of the
                          Income Tax act, 1961.
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={2}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderTop: "2px solid #CDCDCD",
                          borderLeft: "2px solid #CDCDCD",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          Total Payable (Round off)
                        </Typography>
                        {/* <Typography sx={TableBottomtext}>TDS @ 1%</Typography> */}
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderTop: "2px solid #CDCDCD",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          {/* <CommaSeprator Price={tdsAmount} /> */}
                          <CommaSeprator Price={invoiceTotalAmount.toFixed()} />
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow
                      sx={{
                        height: "21px",
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#4361ee",
                          }}
                        ></Typography>
                      </TableCell>

                      <TableCell
                        align="left"
                        colSpan={2}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderLeft: "2px solid #CDCDCD",
                        }}
                      >
                        <Typography sx={TableBottomtext}>
                          {/* Total Payable (Round off) */}
                        </Typography>
                      </TableCell>

                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography sx={TableBottomtext}>
                          {/* <CommaSeprator Price={totalAmountWithTDS} /> */}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "21px",
                        borderTop: "none",
                        borderBottom: "2px solid #CDCDCD",
                      }}
                    >
                      <TableCell
                        align="left"
                        colSpan={4}
                        rowSpan={1}
                        sx={tablecell}
                      ></TableCell>

                      <TableCell
                        align="left"
                        colSpan={2}
                        rowSpan={1}
                        sx={{
                          ...tablecell,
                          borderLeft: "2px solid #CDCDCD",
                        }}
                      >
                        <Typography sx={TableBottomtext}></Typography>
                      </TableCell>

                      <TableCell
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography sx={TableBottomtext}></Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table
                  sx={{
                    borderLeft: "1px solid #CDCDCD",
                  }}
                >
                  <TableBody>
                    <TableRow
                      sx={{
                        height: "21px",
                        borderBottom: "2px solid #CDCDCD",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontWeight: 600,
                              }}
                            >
                              HSN / SAC
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontWeight: 600,
                              }}
                            >
                              Taxable Value
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "220px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontWeight: 600,
                              }}
                            >
                              CGST Tax
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                              width: "25%",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TableBottomtext,
                              }}
                            >
                              Rate
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TableBottomtext,
                              }}
                            >
                              Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "220px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontWeight: 600,
                              }}
                            >
                              SGST Tax
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                              width: "25%",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TableBottomtext,
                              }}
                            >
                              Rate
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TableBottomtext,
                              }}
                            >
                              Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "220px",
                          mx: "auto",
                        }}
                        align="center"
                      >
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "220px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontWeight: 600,
                              }}
                            >
                              Integrated Tax
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                              width: "25%",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TableBottomtext,
                              }}
                            >
                              Rate
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              borderBottom: "none",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            {" "}
                            <Typography
                              sx={{
                                ...TableBottomtext,
                              }}
                            >
                              Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "200px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "200px",
                              border: "none",
                              p: 0,
                              height: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontWeight: 600,
                              }}
                            >
                              Total Tax Amount
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                    </TableRow>

                    {InvoiceData?.ProductData?.map((res, idx) => {
                      return (
                        <TableRow
                          sx={{
                            height: "21px",
                          }}
                        >
                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "180px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "180px",
                                  p: 0,
                                  height: "20px",
                                  border: "none",
                                }}
                              >
                                <Typography sx={TableBottomtext}>
                                  {res?.HSN}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "180px",
                              mx: "auto",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <TableRow
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "right",
                                alignContent: "center",
                                alignItems: "center",
                                px: 0,
                              }}
                            >
                              <TableCell
                                colSpan={2}
                                sx={{
                                  width: "180px",
                                  p: 0,
                                  height: "20px",
                                  border: "none",
                                  textAlign: "right",
                                }}
                              >
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator
                                    Price={res?.TotalPriceWithoutGST}
                                  />
                                  &nbsp;
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              width: "220px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "220px",
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  width: "110px",
                                }}
                              >
                                {" "}
                                {!InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator Price={res?.GST / 2} />%
                                  </Typography>
                                ) : null}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "110px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                  px: 0,
                                }}
                              >
                                {" "}
                                {!InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={res?.TotalGSTAmount / 2}
                                    />
                                    &nbsp;
                                  </Typography>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              width: "220px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "220px",
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  width: "110px",
                                }}
                              >
                                {" "}
                                {!InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator Price={res?.GST / 2} />%
                                  </Typography>
                                ) : null}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "110px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                  px: 0,
                                }}
                              >
                                {" "}
                                {!InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={res?.TotalGSTAmount / 2}
                                    />
                                    &nbsp;
                                  </Typography>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              width: "220px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "220px",
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  width: "110px",
                                }}
                              >
                                {" "}
                                {InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator Price={res?.GST} /> %
                                  </Typography>
                                ) : null}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "110px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                  px: 0,
                                }}
                              >
                                {" "}
                                {InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={res?.TotalGSTAmount}
                                    />
                                    &nbsp;
                                  </Typography>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableCell>

                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "200px",
                              mx: "auto",
                              borderRight: "1px solid #CDCDCD",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "right",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "200px",
                                  border: "none",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                }}
                              >
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.TotalGSTAmount} />
                                  &nbsp;
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                    {InvoiceData?.ProductData?.map((row, idx) => {
                      return row?.AdditionCost?.AdditionCostArray?.map(
                        (res, index) => {
                          let GstOfAdCost = Number(res?.AdCostGST);
                          return (
                            <TableRow
                              sx={{
                                height: "21px",
                              }}
                            >
                              <TableCell
                                sx={{
                                  p: 0,
                                  maxWidth: "180px",
                                  mx: "auto",
                                  borderRight: "1px solid #CDCDCD",
                                }}
                                align="center"
                              >
                                <TableRow
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <TableCell
                                    align="center"
                                    colSpan={2}
                                    sx={{
                                      width: "180px",
                                      p: 0,
                                      height: "20px",
                                      border: "none",
                                    }}
                                  >
                                    <Typography sx={TableBottomtext}>
                                      {res?.AdCostHSN}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableCell>
                              <TableCell
                                sx={{
                                  p: 0,
                                  maxWidth: "180px",
                                  mx: "auto",
                                  borderRight: "1px solid #CDCDCD",
                                }}
                                align="center"
                              >
                                <TableRow
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "right",
                                    alignContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <TableCell
                                    align="center"
                                    colSpan={2}
                                    sx={{
                                      width: "180px",
                                      p: 0,
                                      height: "20px",
                                      border: "none",
                                      textAlign: "right",
                                    }}
                                  >
                                    <Typography sx={TableBottomtext}>
                                      <CommaSeprator
                                        Price={res?.TotalTaxableAmount}
                                      />
                                      &nbsp;
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableCell>
                              <TableCell
                                sx={{
                                  p: 0,
                                  width: "220px",
                                  mx: "auto",
                                }}
                                align="center"
                              >
                                <TableRow
                                  sx={{
                                    width: "220px",
                                  }}
                                >
                                  <TableCell
                                    align="center"
                                    sx={{
                                      borderRight: "1px solid #CDCDCD",
                                      p: 0,
                                      height: "20px",
                                      width: "110px",
                                    }}
                                  >
                                    {" "}
                                    {!InvoiceData?.IsIGST ? (
                                      <Typography sx={TableBottomtext}>
                                        <CommaSeprator
                                          Price={res?.AdCostGST / 2}
                                        />
                                        %
                                      </Typography>
                                    ) : null}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      width: "110px",
                                      borderRight: "1px solid #CDCDCD",
                                      p: 0,
                                      height: "20px",
                                      textAlign: "right",
                                      px: 0,
                                    }}
                                  >
                                    {" "}
                                    {!InvoiceData?.IsIGST ? (
                                      <Typography sx={TableBottomtext}>
                                        {res?.AdCostApplicableOn ===
                                        "PerUnit" ? (
                                          <CommaSeprator
                                            Price={
                                              (res?.PriceWithoutGST *
                                                row?.ProductQuantity *
                                                (GstOfAdCost / 100)) /
                                              2
                                            }
                                          />
                                        ) : (
                                          <CommaSeprator
                                            Price={
                                              (res?.PriceWithoutGST *
                                                (GstOfAdCost / 100)) /
                                              2
                                            }
                                          />
                                        )}
                                        &nbsp;
                                      </Typography>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              </TableCell>
                              <TableCell
                                sx={{
                                  p: 0,
                                  width: "220px",
                                  mx: "auto",
                                }}
                                align="center"
                              >
                                <TableRow
                                  sx={{
                                    width: "220px",
                                  }}
                                >
                                  <TableCell
                                    align="center"
                                    sx={{
                                      borderRight: "1px solid #CDCDCD",
                                      p: 0,
                                      height: "20px",
                                      width: "110px",
                                    }}
                                  >
                                    {" "}
                                    {!InvoiceData?.IsIGST ? (
                                      <Typography sx={TableBottomtext}>
                                        <CommaSeprator
                                          Price={res?.AdCostGST / 2}
                                        />
                                        %
                                      </Typography>
                                    ) : null}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      width: "110px",
                                      borderRight: "1px solid #CDCDCD",
                                      p: 0,
                                      height: "20px",
                                      textAlign: "right",
                                      px: 0,
                                    }}
                                  >
                                    {" "}
                                    {!InvoiceData?.IsIGST ? (
                                      <Typography sx={TableBottomtext}>
                                        {res?.AdCostApplicableOn ===
                                        "PerUnit" ? (
                                          <CommaSeprator
                                            Price={
                                              (res?.PriceWithoutGST *
                                                row?.ProductQuantity *
                                                (GstOfAdCost / 100)) /
                                              2
                                            }
                                          />
                                        ) : (
                                          <CommaSeprator
                                            Price={
                                              (res?.PriceWithoutGST *
                                                (GstOfAdCost / 100)) /
                                              2
                                            }
                                          />
                                        )}
                                        &nbsp;
                                      </Typography>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              </TableCell>
                              <TableCell
                                sx={{
                                  p: 0,
                                  width: "220px",
                                  mx: "auto",
                                }}
                                align="center"
                              >
                                <TableRow
                                  sx={{
                                    width: "220px",
                                  }}
                                >
                                  <TableCell
                                    align="center"
                                    sx={{
                                      borderRight: "1px solid #CDCDCD",
                                      p: 0,
                                      height: "20px",
                                      width: "110px",
                                    }}
                                  >
                                    {" "}
                                    {InvoiceData?.IsIGST ? (
                                      <Typography sx={TableBottomtext}>
                                        <CommaSeprator Price={res?.AdCostGST} />{" "}
                                        %
                                      </Typography>
                                    ) : null}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      width: "110px",
                                      borderRight: "1px solid #CDCDCD",
                                      p: 0,
                                      height: "20px",
                                      textAlign: "right",
                                      px: 0,
                                    }}
                                  >
                                    {" "}
                                    {InvoiceData?.IsIGST ? (
                                      <Typography sx={TableBottomtext}>
                                        {res?.AdCostApplicableOn ===
                                        "PerUnit" ? (
                                          <CommaSeprator
                                            Price={
                                              res?.PriceWithoutGST *
                                              row?.ProductQuantity *
                                              (GstOfAdCost / 100)
                                            }
                                          />
                                        ) : (
                                          <CommaSeprator
                                            Price={
                                              res?.PriceWithoutGST *
                                              (GstOfAdCost / 100)
                                            }
                                          />
                                        )}
                                        &nbsp;
                                      </Typography>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              </TableCell>

                              <TableCell
                                sx={{
                                  p: 0,
                                  maxWidth: "200px",
                                  mx: "auto",
                                  borderRight: "1px solid #CDCDCD",
                                }}
                                align="center"
                              >
                                <TableRow
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "right",
                                    alignContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <TableCell
                                    align="center"
                                    colSpan={2}
                                    sx={{
                                      width: "200px",
                                      border: "none",
                                      p: 0,
                                      height: "20px",
                                      textAlign: "right",
                                    }}
                                  >
                                    <Typography sx={TableBottomtext}>
                                      {res?.AdCostApplicableOn === "PerUnit" ? (
                                        <CommaSeprator
                                          Price={
                                            res?.PriceWithoutGST *
                                            row?.ProductQuantity *
                                            (GstOfAdCost / 100)
                                          }
                                        />
                                      ) : (
                                        <CommaSeprator
                                          Price={
                                            res?.PriceWithoutGST *
                                            (GstOfAdCost / 100)
                                          }
                                        />
                                      )}
                                      &nbsp;
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      );
                    })}

                    {ChargesArray?.map((res, idx) => {
                      return (
                        <TableRow
                          sx={{
                            height: "21px",
                          }}
                        >
                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "180px",
                              mx: "auto",
                              borderRight: "1px solid #CDCDCD",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "180px",
                                  p: 0,
                                  height: "20px",
                                  border: "none",
                                }}
                              >
                                <Typography sx={TableBottomtext}>
                                  {res?.hsn}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "180px",
                              mx: "auto",
                              borderRight: "1px solid #CDCDCD",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "right",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "180px",
                                  p: 0,
                                  height: "20px",
                                  border: "none",
                                  textAlign: "right",
                                }}
                              >
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.taxableValue} />
                                  &nbsp;
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              width: "220px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "220px",
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  width: "110px",
                                }}
                              >
                                {" "}
                                {!InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator Price={res?.gst / 2} />%
                                  </Typography>
                                ) : null}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "110px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                  px: 0,
                                }}
                              >
                                {" "}
                                {!InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={res?.totalTaxAmount / 2}
                                    />
                                    &nbsp;
                                  </Typography>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              width: "220px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "220px",
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  width: "110px",
                                }}
                              >
                                {" "}
                                {!InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator Price={res?.gst / 2} />%
                                  </Typography>
                                ) : null}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "110px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                  px: 0,
                                }}
                              >
                                {" "}
                                {!InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={res?.totalTaxAmount / 2}
                                    />
                                    &nbsp;
                                  </Typography>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              width: "220px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "220px",
                              }}
                            >
                              <TableCell
                                align="center"
                                sx={{
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  width: "110px",
                                }}
                              >
                                {" "}
                                {InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator Price={res?.gst} /> %
                                  </Typography>
                                ) : null}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "110px",
                                  borderRight: "1px solid #CDCDCD",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                  px: 0,
                                }}
                              >
                                {" "}
                                {InvoiceData?.IsIGST ? (
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={res?.totalTaxAmount}
                                    />
                                    &nbsp;
                                  </Typography>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          </TableCell>

                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "200px",
                              mx: "auto",
                              borderRight: "1px solid #CDCDCD",
                            }}
                            align="center"
                          >
                            <TableRow
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "right",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TableCell
                                align="center"
                                colSpan={2}
                                sx={{
                                  width: "200px",
                                  border: "none",
                                  p: 0,
                                  height: "20px",
                                  textAlign: "right",
                                  px: 0,
                                }}
                              >
                                <Typography sx={TableBottomtext}>
                                  <CommaSeprator Price={res?.totalTaxAmount} />
                                  &nbsp;
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow
                      sx={{
                        height: "21px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                            }}
                          >
                            <Typography sx={TableBottomtext}></Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "180px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                              textAlign: "right",
                            }}
                          >
                            <Typography sx={TableBottomtext}>&nbsp;</Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                              textAlign: "right",
                            }}
                          >
                            <Typography sx={TableBottomtext}>&nbsp;</Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                              textAlign: "right",
                            }}
                          >
                            <Typography sx={TableBottomtext}>&nbsp;</Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          width: "220px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "180px",
                              p: 0,
                              height: "20px",
                              border: "none",
                              textAlign: "right",
                            }}
                          >
                            <Typography
                              sx={{ ...TableBottomtext, fontWeight: 600 }}
                            >
                              Total GST Amount &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>

                      <TableCell
                        sx={{
                          p: 0,
                          maxWidth: "200px",
                          mx: "auto",
                          borderRight: "1px solid #CDCDCD",
                        }}
                        align="center"
                      >
                        <TableRow
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "right",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "200px",
                              border: "none",
                              p: 0,
                              height: "20px",
                              textAlign: "right",
                              px: 0,
                            }}
                          >
                            <Typography
                              sx={{ ...TableBottomtext, fontWeight: 600 }}
                            >
                              <CommaSeprator Price={totalTaxAmount} />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableCell>
                    </TableRow>
                  </TableBody>

                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={7}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        &nbsp;
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={7}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography sx={TableBottomtext}>
                          Ceritified that the particulars given above are true
                          and correct
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={7}
                        rowSpan={1}
                        sx={tablecell}
                      >
                        <Typography sx={TableBottomtext}>
                          This is a System Generated Invoice by BXI WORLD LLP
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default SellerProformaInvoiceDetails;

const CommongTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",

  color: "#6B7A99",
};

const TextStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "left",
  opacity: 1,
  color: "#000000",
};

const TextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  opacity: 1,
  color: "#000000",
  width: "120px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const AddressTextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "#505050",
  opacity: 1,

  width: "300px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#FFFFFF",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  opacity: 0.5,
  color: "#000000",
  opacity: 1,
};

const tablecell = { borderRight: "1px solid #CDCDCD", p: 0.2 };
