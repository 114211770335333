import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import StacsOfCoinIcon from "../../assets/Images/CommonImages/BXIToken.svg";
import CommaSeprator from "../../components/CommaSeprator";
import useGetCompanyFullData from "../../Hooks/CompanyData/useGetCompanyFullData";
import BxiCoin from "../../assets/Images/CommonImages/BXIToken.svg";
import axios from "axios";
import OrderProgressBar from "../../components/ProgressBar/OrderProgressBar";

const ProductOrderSummary = ({ ProductSummaryData, CompanyData, TDS }) => {
  const componentRef = useRef();

  const [MemberShipData, setMemberShipData] = useState();

  const itemDetails = [
    {
      item: "Seller Name :",
      value:
        ProductSummaryData?.PurchaseOrderData?.SellerDetails?.SellerCompanyName,
    },
    {
      item: "Address :",
      value:
        ProductSummaryData?.PurchaseOrderData?.SellerDetails?.Address
          ?.AddressLine,
    },
    {
      item: "GSTIN :",
      value: ProductSummaryData?.PurchaseOrderData?.SellerDetails?.GSTIN,
    },
    {
      item: "State :",
      value:
        ProductSummaryData?.PurchaseOrderData?.SellerDetails?.Address?.State,
    },
  ];
  const pror = [
    {
      item: "Purchase Order Number :",
      value: ProductSummaryData?.PoNumber,
    },
    {
      item: "Purchase Order Date :",
      value: new Date(ProductSummaryData?.PoDate).toDateString(),
    },
  ];

  let Seller_amount = 0;
  let BXI_amount = 0;
  let Coins = 0;
  if (ProductSummaryData) {
    Seller_amount =
      ProductSummaryData?.INRDetails?.totalGstAmount +
      ProductSummaryData?.INRDetails?.totalGstInrOfAdCost +
      ProductSummaryData?.INRDetails?.totalValueInrOfAdCost +
      (ProductSummaryData?.BuyerChoosedTransportation?.TransportationType !==
      "notransportation"
        ? ProductSummaryData?.INRDetails?.InsuranceCost +
          ProductSummaryData?.INRDetails?.packagingCost
        : 0) +
      (ProductSummaryData?.BuyerChoosedTransportation?.TransportationType ===
      "Seller"
        ? ProductSummaryData?.INRDetails?.totalTransportationCost +
          ProductSummaryData?.INRDetails?.totalTransportationCostGST
        : 0);

    BXI_amount =
      ProductSummaryData?.INRDetails?.bxiCommisionOnTransportation +
      ProductSummaryData?.INRDetails?.bxiCommisionOnTransportationGST +
      ProductSummaryData?.INRDetails?.totalBxiCommission +
      ProductSummaryData?.INRDetails?.bxiCommisionGST -
      ProductSummaryData?.INRDetails?.TDS +
      (ProductSummaryData?.BuyerChoosedTransportation?.TransportationType !==
        "Seller" &&
      ProductSummaryData?.BuyerChoosedTransportation?.TransportationType !==
        "notransportation"
        ? ProductSummaryData?.INRDetails?.totalTransportationCost +
          ProductSummaryData?.INRDetails?.totalTransportationCostGST +
          ProductSummaryData?.INRDetails?.bxiCommisionOnTransportation +
          ProductSummaryData?.INRDetails?.bxiCommisionOnTransportationGST -
          ProductSummaryData?.INRDetails?.ShippingTDS
        : 0);

    Coins = ProductSummaryData?.WalletData?._balance;
  }

  let TotalAdditionalCostWtihoutGSTInBXI = 0;
  let TotalPriceWithoutGST = 0;
  let GrandTotal = 0;
  let totaladditionalcostGstinrupee = 0;
  let totaladditionalcostGstinbxi = 0;

  const { data: companyData } = useGetCompanyFullData();

  let BuyerId = ProductSummaryData?.BuyerCompanyId;

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(`/company/get_company/${BuyerId}`, {
        withCredentials: true,
      });
      const data =
        response.data.memberships[response?.data?.memberships?.length - 1]
          .MembershipPlan;
      setMemberShipData(data);
    } catch (error) {}
  };

  useEffect(() => {
    GetCompanyByID();
  }, [ProductSummaryData]);

  let BxiCommision = ProductSummaryData?.PurchaseOrderData?.CouponData
    ?.BXICommission
    ? Number(ProductSummaryData?.PurchaseOrderData?.CouponData?.BXICommission)
    : Number(MemberShipData?.GST);

  const ChargesArray = [
    {
      name: "Packaging charges",
      gst: Number(ProductSummaryData?.PackagingData?.packagingcostgstfee),
      taxableValue: Number(ProductSummaryData?.PackagingData?.packagingcosts),
      totalTaxAmount:
        (Number(ProductSummaryData?.PackagingData?.packagingcosts) *
          Number(ProductSummaryData?.PackagingData?.packagingcostgstfee)) /
        100,
    },
    {
      name: "Logistics Charges",
      gst:
        ProductSummaryData?.BuyerChoosedTransportation?.TransportationType ===
        "notransportation"
          ? 0
          : Number(ProductSummaryData?.Transportationdata?.gstFee || 0),
      taxableValue:
        ProductSummaryData?.BuyerChoosedTransportation?.TransportationType ===
        "notransportation"
          ? 0
          : Number(
              ProductSummaryData?.Transportationdata?.transportationfee || 0
            ),
      totalTaxAmount:
        ProductSummaryData?.BuyerChoosedTransportation?.TransportationType ===
        "notransportation"
          ? 0
          : (Number(
              ProductSummaryData?.Transportationdata?.transportationfee || 0
            ) *
              Number(ProductSummaryData?.Transportationdata?.gstFee || 0)) /
            100,
    },
    {
      name: "BXI Commission",
      gst: 18,
      commission: BxiCommision,
      taxableValue:
        (Number(ProductSummaryData?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
        100,
      totalTaxAmount:
        (((Number(ProductSummaryData?.PITotals?.TotalCoinsWithAdditionalCost) *
          BxiCommision) /
          100) *
          18) /
        100,
    },
  ];

  let ChargesTaxableValue = 0;
  let ChargesTaxableAmount = 0;

  ChargesArray?.map((res, idx) => {
    ChargesTaxableValue += Number(res?.taxableValue);
    ChargesTaxableAmount += Number(res?.totalTaxAmount);
  });

  let totalTaxAmount =
    ProductSummaryData?.PITotals?.TotalGstAmount +
    ProductSummaryData?.PITotals?.TotalAdCostGstAmount +
    ChargesTaxableAmount +
    ProductSummaryData?.INRDetails?.InsuranceCostGstValue;

  ProductSummaryData?.PurchaseOrderData?.ProductData?.map((el, idx) => {
    if (ProductSummaryData?.IsVoucher) {
      TotalAdditionalCostWtihoutGSTInBXI +=
        el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
    } else {
      TotalAdditionalCostWtihoutGSTInBXI +=
        el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
    }

    if (
      el &&
      (el?.AdditionCost?.AdditionCostArray?.length > 0 ||
        el?.AdditionCost?.AdditionCostArrayData?.length > 0)
    ) {
      // Choose the correct array to iterate based on availability
      const additionalCostArray =
        el?.AdditionCost?.AdditionCostArray?.length > 0
          ? el?.AdditionCost?.AdditionCostArray
          : el?.AdditionCost?.AdditionCostArrayData;

      // Iterate over the array
      additionalCostArray.forEach((item, index) => {
        // Check currency type and calculate total additional cost accordingly
        if (item.currencyType === "₹") {
          totaladditionalcostGstinrupee +=
            (item.TotalTaxableAmount * item.AdCostGST) / 100;
        } else if (item.currencyType === "BXITokens") {
          totaladditionalcostGstinbxi +=
            (item.TotalTaxableAmount * item.AdCostGST) / 100;
        }
      });
    }

    TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
    if (el?.ProductTypeName === "Media") {
      GrandTotal += el?.TotalPriceWithoutGST;
    } else if (ProductSummaryData?.IsVoucher) {
      GrandTotal +=
        el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI +
        el?.TotalPriceWithoutGST
          ? el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI +
            el?.TotalPriceWithoutGST
          : el?.TotalPriceInBXI;
    } else {
      GrandTotal +=
        el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI +
        el?.TotalPriceWithoutGST
          ? el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI +
            el?.TotalPriceWithoutGST
          : el?.TotalPriceInBXI;
    }
  });

  return (
    <Paper
      sx={{
        bgcolor: "transparent",
        boxShadow: "none",
        borderRadius: "20px",
        height: "auto",
        minHeight: "520px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      elevation={0}
      ref={componentRef}
    >
      <OrderProgressBar
        type={"productordersummary"}
        Data={ProductSummaryData}
      />
      <Box
        ref={componentRef}
        sx={{ width: "100%", mt: "20px", bgcolor: "#fff" }}
      >
        <Grid
          container
          gap={8}
          sx={{
            py: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xl={5.5}
            lg={5.5}
            md={5.5}
            sm={12}
            xs={12}
            sx={{
              ...gridstyle,
              width: "830px",
              height: "auto",
              position: "relative",
            }}
          >
            <Box sx={mainbox}>
              <Typography sx={headbox}>
                &nbsp;&nbsp;&nbsp;Supplier Details
              </Typography>
              <Typography
                sx={{
                  ...headbox,
                  fontSize: "14px",
                  float: "right",
                  textAlign: "right",
                  mr: "10px",
                }}
              >
                Order Id : {ProductSummaryData?.OrderId}
              </Typography>
            </Box>
            <Box sx={contentbox}>
              {itemDetails?.map((el, idx) => {
                return (
                  <Box sx={mapbox} key={idx}>
                    <Typography sx={elitem}>{el.item}</Typography>
                    <Typography
                      sx={{
                        ...elvalue,
                        width: "85%",
                        textAlign: "left",
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {el.value}
                    </Typography>
                  </Box>
                );
              })}

              <Box sx={{ mt: "20px", width: "90%" }}>
                {pror?.map((el, idx) => {
                  return (
                    <Box sx={{ ...mapbox }} key={idx}>
                      <Typography
                        sx={{
                          ...elitem,
                          width: "300px",
                          textAlign: "left",
                        }}
                      >
                        {el.item}
                      </Typography>
                      <Typography
                        sx={{
                          ...elvalue,
                          width: "85%",
                          textAlign: "left",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {el.value}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>

              <Typography
                sx={{
                  ...selername,
                  justifyContent: "flex-end",
                  position: "absolute",
                  mt: 8,
                }}
              >
                Code :{" "}
                {
                  ProductSummaryData?.PurchaseOrderData?.SellerDetails
                    ?.StateCode
                }
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xl={5.5}
            lg={5.5}
            md={5.5}
            sm={12}
            xs={12}
            sx={{
              ...gridstyle,
              width: "399px",
              height: "100%",
              minHeight: "380px",
              // bgcolor: "red",
            }}
          >
            <Box sx={headbox2}>
              <Typography sx={detailtext}>Coins + INR Details</Typography>
              <Typography sx={detailtext2}>Details with more info</Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                py: 2,
              }}
            >
              <Table sx={{ maxWidth: "85%" }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "left",
                        }}
                      >
                        Particulars
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        Basic Value
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        GST (INR)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0.3,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography sx={{ ...TableBottomtext, fontWeight: 600 }}>
                        Total (Coins+INR)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Product Cost */}
                  {ProductSummaryData?.INRDetails?.totalGstAmount !== 0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          Product Cost (Coins){" "}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              TotalPriceWithoutGST
                                ? TotalPriceWithoutGST
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails?.totalGstAmount
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails?.totalGstAmount
                                ? ProductSummaryData?.INRDetails
                                    ?.totalGstAmount + TotalPriceWithoutGST
                                : 0.0
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {/* Packaging Cost */}
                  {ProductSummaryData?.INRDetails?.packagingCost !== 0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          Packaging Cost
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails?.packagingCost
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              (ProductSummaryData?.INRDetails?.packagingCost *
                                ProductSummaryData?.INRDetails
                                  ?.packagingCostGST) /
                              100
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails?.packagingCost +
                              (ProductSummaryData?.INRDetails?.packagingCost *
                                ProductSummaryData?.INRDetails
                                  ?.packagingCostGST) /
                                100
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {/* Additional Cost (INR)*/}
                  {ProductSummaryData?.INRDetails?.totalGstInrOfAdCost +
                    ProductSummaryData?.INRDetails?.totalValueInrOfAdCost !==
                    0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          Additional Cost (INR)
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails
                                ?.totalValueInrOfAdCost
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              totaladditionalcostGstinrupee
                                ? totaladditionalcostGstinrupee
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {ProductSummaryData?.INRDetails
                            ?.totalValueInrOfAdCost ? (
                            <>
                              {ProductSummaryData?.INRDetails
                                ?.totalValueInrOfAdCost !== 0 && (
                                <CommaSeprator
                                  Price={
                                    ProductSummaryData?.INRDetails
                                      ?.totalValueInrOfAdCost +
                                    totaladditionalcostGstinrupee
                                  }
                                />
                              )}
                            </>
                          ) : ProductSummaryData?.POTotals
                              ?.TotalAdditionalCostInRupee ? (
                            <>
                              {ProductSummaryData?.POTotals
                                ?.TotalAdditionalCostInRupee !== 0 && (
                                <CommaSeprator
                                  Price={
                                    ProductSummaryData?.POTotals
                                      ?.TotalAdditionalCostInRupee +
                                    totaladditionalcostGstinrupee
                                  }
                                />
                              )}
                            </>
                          ) : (
                            "0.00"
                          )}
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {/* Additional Cost (Coins)*/}
                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderLeft: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "left" }}
                      >
                        Additional Cost (Coins)
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            TotalAdditionalCostWtihoutGSTInBXI
                              ? TotalAdditionalCostWtihoutGSTInBXI
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            totaladditionalcostGstinbxi
                              ? totaladditionalcostGstinbxi
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "1px solid #c4d0dd",
                        borderRight: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{ ...TableBottomtext, textAlign: "right" }}
                      >
                        <CommaSeprator
                          Price={
                            TotalAdditionalCostWtihoutGSTInBXI +
                            totaladditionalcostGstinbxi
                              ? TotalAdditionalCostWtihoutGSTInBXI +
                                totaladditionalcostGstinbxi
                              : "0.00"
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* Logistic Cost */}
                  {ProductSummaryData?.BuyerChoosedTransportation
                    ?.TransportationType !== "notransportation" ? (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          Logistic Cost
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails
                                ?.totalTransportationCost
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails
                                ?.totalTransportationCostGST
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails
                                ?.totalTransportationCost +
                              ProductSummaryData?.INRDetails
                                ?.totalTransportationCostGST
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {/* BXI Commission */}
                  {ProductSummaryData?.INRDetails?.totalBxiCommission !== 0 && (
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderLeft: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "left" }}
                        >
                          BXI Commission
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails?.totalBxiCommission
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          {" "}
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails?.bxiCommisionGST
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "1px solid #c4d0dd",
                          borderRight: "2px solid #c4d0dd",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, textAlign: "right" }}
                        >
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails?.totalBxiCommission
                                ? ProductSummaryData?.INRDetails
                                    ?.totalBxiCommission +
                                  ProductSummaryData?.INRDetails
                                    ?.bxiCommisionGST
                                : 0.0
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {/* Insurance Cost */}
                  {ProductSummaryData?.INRDetails?.InsuranceCost !== 0 &&
                    (ProductSummaryData?.BuyerChoosedTransportation
                      ?.TransportationType !== "notransportation" ? (
                      <TableRow
                        sx={{
                          height: "24px",
                        }}
                      >
                        <TableCell
                          sx={{
                            p: 0,
                            border: "1px solid #c4d0dd",
                            borderLeft: "2px solid #c4d0dd",
                          }}
                          align="center"
                        >
                          <Typography
                            sx={{ ...TableBottomtext, textAlign: "left" }}
                          >
                            Insurance Cost
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            border: "1px solid #c4d0dd",
                          }}
                          align="center"
                        >
                          <Typography
                            sx={{ ...TableBottomtext, textAlign: "right" }}
                          >
                            <CommaSeprator
                              Price={
                                ProductSummaryData?.INRDetails?.InsuranceCost
                              }
                            />
                            &nbsp;
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            border: "1px solid #c4d0dd",
                          }}
                          align="center"
                        >
                          <Typography
                            sx={{ ...TableBottomtext, textAlign: "right" }}
                          >
                            <CommaSeprator
                              Price={
                                (ProductSummaryData?.INRDetails
                                  ?.InsuranceCostGst *
                                  ProductSummaryData?.INRDetails
                                    ?.InsuranceCost) /
                                100
                              }
                            />
                            &nbsp;
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0,
                            border: "1px solid #c4d0dd",
                            borderRight: "2px solid #c4d0dd",
                          }}
                          align="center"
                        >
                          <Typography
                            sx={{ ...TableBottomtext, textAlign: "right" }}
                          >
                            <CommaSeprator
                              Price={
                                ProductSummaryData?.INRDetails?.InsuranceCost +
                                ProductSummaryData?.INRDetails
                                  ?.InsuranceCostGstValue
                              }
                            />
                            &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null)}

                  <TableRow
                    sx={{
                      height: "24px",
                    }}
                  >
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                        bgcolor: "#f3f6f9",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "left",
                        }}
                      >
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={
                            ProductSummaryData?.INRDetails?.WithoutGSTValue
                              ? ProductSummaryData?.INRDetails?.WithoutGSTValue
                              : 0.0
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={totalTaxAmount ? totalTaxAmount : 0.0}
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        border: "2px solid #c4d0dd",
                        borderRight: "2px solid #c4d0dd",
                      }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          fontWeight: 600,
                          textAlign: "right",
                        }}
                      >
                        <CommaSeprator
                          Price={
                            ProductSummaryData?.INRDetails?.WithoutGSTValue +
                            totalTaxAmount
                              ? ProductSummaryData?.INRDetails
                                  ?.WithoutGSTValue + totalTaxAmount
                              : 0.0
                          }
                        />
                        &nbsp;
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              <br />
              <Box
                sx={{
                  width: "85%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Table sx={{ maxWidth: "57%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          bgcolor: "#f3f6f9",
                          //   borderRight: "none",
                        }}
                        align="center"
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          TDS @{TDS}%
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator
                            Price={
                              ProductSummaryData?.INRDetails?.TDS
                                ? ProductSummaryData?.INRDetails?.TDS
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Box>
              <br />
              <br />
              <Box
                sx={{
                  width: "85%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Table sx={{ maxWidth: "71%" }} aria-label="customized table">
                  <TableHead>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          bgcolor: "#f3f6f9",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={3}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Payment Details
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Buyer To Seller
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          <CommaSeprator
                            Price={
                              TotalAdditionalCostWtihoutGSTInBXI +
                              TotalPriceWithoutGST -
                              ProductSummaryData?.INRDetails?.TDS
                                ? TotalAdditionalCostWtihoutGSTInBXI +
                                  TotalPriceWithoutGST -
                                  ProductSummaryData?.INRDetails?.TDS
                                : "0.00"
                            }
                          />
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Coins
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Buyer To Seller
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          {ProductSummaryData?.BuyerChoosedTransportation
                            ?.TransportationType === "notransportation" ||
                          ProductSummaryData?.IsMedia ||
                          ProductSummaryData?.IsVoucher ? (
                            <CommaSeprator
                              Price={
                                ProductSummaryData?.INRDetails?.TotalInrToPay
                                  ? ProductSummaryData?.INRDetails
                                      ?.TotalInrToPay
                                  : 0.0
                              }
                            />
                          ) : (
                            <>
                              {ProductSummaryData?.INRDetails?.packagingCost ||
                              ProductSummaryData?.INRDetails?.InsuranceCost ? (
                                <CommaSeprator
                                  Price={
                                    ProductSummaryData?.INRDetails
                                      ?.TotalInrToPay
                                      ? ProductSummaryData?.INRDetails
                                          ?.TotalInrToPay
                                      : 0.0
                                  }
                                />
                              ) : (
                                <CommaSeprator
                                  Price={
                                    ProductSummaryData?.INRDetails
                                      ?.TotalInrToPay
                                      ? ProductSummaryData?.INRDetails
                                          ?.TotalInrToPay
                                      : 0.0
                                  }
                                />
                              )}
                            </>
                          )}
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Inr
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        height: "24px",
                      }}
                    >
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderRight: "none",
                        }}
                        align="center"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{
                            ...TableBottomtext,
                            fontWeight: 600,
                            textAlign: "left",
                          }}
                        >
                          From Seller to BXI
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="right"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          {ProductSummaryData?.IsMedia ? (
                            <CommaSeprator
                              Price={
                                ProductSummaryData?.INRDetails
                                  ?.TotalBxiCommission
                                  ? ProductSummaryData?.INRDetails
                                      ?.TotalBxiCommission +
                                    ProductSummaryData?.INRDetails
                                      ?.BxiCommisionGST +
                                    ProductSummaryData?.INRDetails?.TDS
                                  : 0.0
                              }
                            />
                          ) : (
                            <CommaSeprator
                              Price={
                                ProductSummaryData?.INRDetails
                                  ?.totalBxiCommission
                                  ? ProductSummaryData?.INRDetails
                                      ?.totalBxiCommission +
                                    ProductSummaryData?.INRDetails
                                      ?.bxiCommisionGST +
                                    ProductSummaryData?.INRDetails?.TDS
                                  : 0.0
                              }
                            />
                          )}
                          &nbsp;
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0,
                          border: "2px solid #c4d0dd",
                          //   borderLeft: "none",
                        }}
                        align="left"
                        colSpan={1}
                        rowSpan={1}
                      >
                        <Typography
                          sx={{ ...TableBottomtext, fontWeight: 600 }}
                        >
                          &nbsp;Inr
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Box>
            </Box>
          </Grid>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "96%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  ...elitem,
                  width: "80%",
                  textAlign: "left",
                }}
              >
                <b>NOTE :-</b>
                <br />
                BXI Commission is always charged on Barter Coins.
                <br />
                {TDS}% TDS is collected in INR on Total Gross Value.
                <br />
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/* <Grid
          container
          gap={8}
          sx={{
            py: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xl={11.7}
            lg={11.7}
            md={11.7}
            sm={11.7}
            xs={11.7}
            sx={{
              ...gridstyle,
              width: "578px",
              height: "276px",
            }}
          >
            <Box sx={headbox2}>
              <Typography sx={detailtext}>Item Details</Typography>
              <Typography sx={detailtext2}>Details with more info</Typography>
            </Box>

            <Grid
              container
              sx={{
                width: "100%",
                height: "20%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "0rem 0rem 0rem 4rem",
                justifyContent: "center",
              }}
            >
              <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                <Typography sx={{ ...headtext, textAlign: "left" }}>
                  No
                </Typography>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography sx={{ ...headtext, textAlign: "left" }}>
                  Item
                </Typography>
              </Grid>
              <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                <Typography sx={headtext}>Quantity</Typography>
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                <Typography sx={headtext}>Rate / Unit</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                width: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                padding: "1rem 1rem 1rem 4rem",
                justifyContent: "end",
                overflow: "scroll",
              }}
            >
              {ProductSummaryData?.InvoiceData?.ProductData?.map((el, idx) => {
                return (
                  <Grid container sx={{ mt: "10px" }}>
                    <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                      <Typography
                        sx={{
                          ...headtext,
                          fontWeight: 500,
                          textAlign: "left",
                          color: "#6B7A99",
                          opacity: 1,
                        }}
                      >
                        {idx + 1}
                      </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...headtext,
                          fontWeight: 500,
                          textAlign: "left",
                          color: "#6B7A99",
                          opacity: 1,
                        }}
                      >
                        {el.ProductName}
                      </Typography>
                    </Grid>
                    <Grid item xl={2.5} lg={2.5} md={2.5} sm={2.5} xs={2.5}>
                      <Typography
                        sx={{
                          ...headtext,
                          fontWeight: 500,

                          color: "#6B7A99",
                          opacity: 1,
                        }}
                      >
                        {el.ProductQuantity}
                      </Typography>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                      <Typography
                        sx={{
                          ...headtext,
                          fontWeight: 500,

                          color: "#6B7A99",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator Price={el?.PricePerUnitWithoutGST} />
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Box sx={totaltextbox}>
              <Box sx={totaltextsec}>
                <Typography sx={totaltext}>Total</Typography>
                <Typography
                  sx={{
                    ...totaltext,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "5px",
                  }}
                >
                  <Box
                    component="img"
                    src={StacsOfCoinIcon}
                    sx={{
                      width: "13px",
                      height: "13px",
                    }}
                  />
                  <CommaSeprator
                    Price={
                      ProductSummaryData?.BXITokenDetails?.totalProductTokens +
                      ProductSummaryData?.BXITokenDetails?.totalAdCostTokens
                    }
                  />
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid> */}
      </Box>
    </Paper>
  );
};

export default ProductOrderSummary;

const headtext = {
  width: "100%",
  textAlign: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "15px",
  color: "#6B7A99",
  opacity: 1,
};

const mainbox = {
  width: "100%",
  height: "48px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const headbox = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const contentbox = {
  width: "100%",
  height: "50%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // gap: "3px",
};

const headbox2 = {
  width: "100%",
  height: "64px",
  background: "#F3F6F9",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const selername = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  opacity: 0.7,
};

const mapbox = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const elitem = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const totaltext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
};

const totaltextbox = {
  width: "100%",
  height: "10%",
  borderTop: "1px solid rgba(149, 144, 168, 0.1)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};

const elvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "#6B7A99",
  opacity: 1,
};

const detailtext = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#6B7A99",
};

const detailtext2 = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#6B7A99",
  opacity: 0.4,
};

const totaltextsec = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const inrvalue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "10px",
  textAlign: "center",
  color: "#6B7A99",
};

const gridstyle = {
  border: "1px solid rgba(24, 2, 12, 0.05)",
  borderRadius: "10px 10px 0px 0",
};

const HeaderIconStyle = {
  // border: "1px solid #8C8C8C",
  // borderRadius: "6px",
  height: "auto",
  width: {
    xl: "25px",
    lg: "25px",
    md: "25px",
    sm: "20px",
    xs: "20px",
  },
  mt: { xl: 0, lg: 0, md: 0, sm: 1, xs: 1 },
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  opacity: 0.5,
  color: "#8384a1",
  opacity: 1,
};
