import { Breadcrumbs, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BreadCrumbHeader = (Props) => {
  const urlLocation = useLocation();
  const location = useLocation();
  const url = location.pathname;
  const splitedurl = url.split("/");
  let navigate = useNavigate();

  const [breadData, setBreaddata] = useState([]);

  useEffect(() => {
    let myArray = JSON.parse(localStorage.getItem("myArray")) || [];
    const isAlreadyAdded = myArray.some(
      (item) =>
        item.url === splitedurl[splitedurl.length - 1] ||
        item.url === splitedurl[splitedurl.length - 2]
    );

    if (!isAlreadyAdded) {
      if (
        splitedurl[splitedurl.length - 2] === "purchaseorderdetails" ||
        splitedurl[splitedurl.length - 2] === "performainvoice" ||
        splitedurl[splitedurl.length - 2] === "choosetransport" ||
        splitedurl[splitedurl.length - 2] === "ordersummerydetails" ||
        splitedurl[splitedurl.length - 2] === "mediapurchaseorderdetails" ||
        splitedurl[splitedurl.length - 2] === "sellerdetailedordersummary" ||
        splitedurl[splitedurl.length - 2] === "hotelsVoucher" ||
        splitedurl[splitedurl.length - 2] === "hotelsproductinfo" ||
        splitedurl[splitedurl.length - 2] === "hotelstechinfo" ||
        splitedurl[splitedurl.length - 2] === "voucherdesign" ||
        splitedurl[splitedurl.length - 2] === "spacificvoucher" ||
        splitedurl[splitedurl.length - 2] === "lifestyleproductinfo" ||
        splitedurl[splitedurl.length - 2] === "lifestyletechinfo" ||
        splitedurl[splitedurl.length - 2] === "lifestylegolive" ||
        splitedurl[splitedurl.length - 2] === "texttileproductInfo" ||
        splitedurl[splitedurl.length - 2] === "technicalinfo" ||
        splitedurl[splitedurl.length - 2] === "golive" ||
        splitedurl[splitedurl.length - 2] === "officesupplyproductinfo" ||
        splitedurl[splitedurl.length - 2] === "officesupplytechinfo" ||
        splitedurl[splitedurl.length - 2] === "officesupplygolive" ||
        splitedurl[splitedurl.length - 2] === "electronicsproductinfo" ||
        splitedurl[splitedurl.length - 2] === "electronicstechinfo" ||
        splitedurl[splitedurl.length - 2] === "electronicsgolive" ||
        splitedurl[splitedurl.length - 2] === "mobilityproductinfo" ||
        splitedurl[splitedurl.length - 2] === "mobilitytechinfo" ||
        splitedurl[splitedurl.length - 2] === "mobilitygolive" ||
        splitedurl[splitedurl.length - 2] === "fmcgproductinfo" ||
        splitedurl[splitedurl.length - 2] === "fmcgtechinfo" ||
        splitedurl[splitedurl.length - 2] === "fmcggolive" ||
        splitedurl[splitedurl.length - 2] === "othersproductinfo" ||
        splitedurl[splitedurl.length - 2] === "otherstechinfo" ||
        splitedurl[splitedurl.length - 2] === "othersgolive" ||
        splitedurl[splitedurl.length - 2] === "restaurantproductinfo" ||
        splitedurl[splitedurl.length - 2] === "restauranttechinfo" ||
        splitedurl[splitedurl.length - 2] === "restaurantgolive" ||
        splitedurl[splitedurl.length - 2] === "voucherdetail" ||
        splitedurl[splitedurl.length - 2] === "productdetail" ||
        splitedurl[splitedurl.length - 2] === "mediabuying" ||
        splitedurl[splitedurl.length - 2] === "regeneratemandate" ||
        splitedurl[splitedurl.length - 2] === "allproductpreview" ||
        splitedurl[splitedurl.length - 2] === "sellerperformainvoice"
      ) {
        myArray.push({
          url: splitedurl[splitedurl.length - 2],
          name: Props.MainText,
          id: splitedurl[splitedurl.length - 1],
        });
      } else {
        myArray.push({
          url: splitedurl[splitedurl.length - 1],
          name: Props.MainText,
          id: "",
        });
      }

      localStorage.setItem("myArray", JSON.stringify(myArray));
    } else {
      const currentIndex = myArray.findIndex(
        (item) =>
          item.url === splitedurl[splitedurl.length - 1] ||
          item.url === splitedurl[splitedurl.length - 2]
      );
      if (currentIndex !== -1) {
        myArray = myArray.slice(0, currentIndex + 1);
        localStorage.setItem("myArray", JSON.stringify(myArray));
      }
    }
  }, [urlLocation]);

  useEffect(() => {
    let myArray = JSON.parse(localStorage.getItem("myArray")) || [];
    if (Props?.showbreadcrumb) {
      setBreaddata(myArray);
    }
  }, []);

  const handleBreadcrumbClick = (index) => {
    const targetUrl = `${breadData[index].url}/${breadData[index].id}`;
    const newPath = splitedurl.includes("hotelsVoucher")
      ? `/home/hotelsVoucher/${targetUrl}`
      : splitedurl.includes("lifestyle")
      ? `/home/lifestyle/${targetUrl}`
      : splitedurl.includes("textile")
      ? `/home/textile/${targetUrl}`
      : splitedurl.includes("officesupply")
      ? `/home/officesupply/${targetUrl}`
      : splitedurl.includes("electronics")
      ? `/home/electronics/${targetUrl}`
      : splitedurl.includes("mobility")
      ? `/home/mobility/${targetUrl}`
      : splitedurl.includes("fmcg")
      ? `/home/fmcg/${targetUrl}`
      : splitedurl.includes("others")
      ? `/home/others/${targetUrl}`
      : splitedurl.includes("restaurant")
      ? `/home/restaurant/${targetUrl}`
      : `/home/${targetUrl}`;

    // navigate(newPath);

    const remainingBreadcrumbs = breadData.slice(0, index + 1);
    localStorage.setItem("myArray", JSON.stringify(remainingBreadcrumbs));
  };

  return (
    <Paper
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: "10px",
        marginBottom: Props?.Margin ? "2rem" : "1rem",
        minHeight: "40px",
        width: "100%",
        mx: "auto",
      }}
      gap={0.5}
      elevation={0}
    >
      <Typography sx={maintext}>{Props.MainText}</Typography>
      <Breadcrumbs separator=">" aria-label="breadcrumb" sx={menutext}>
        <Typography
          key={-1}
          onClick={() => navigate("/home")}
          style={{ cursor: "pointer" }}
          sx={{ ...TextStyleInsidePaper, fontSize: "13px" }}
        >
          Home
        </Typography>
        {breadData?.map((item, index) => {
          const capitalizeFirstLetter = (str) => {
            return str.replace(/\b\w/g, (char) => char.toUpperCase());
          };
          return (
            <Typography
              key={index}
              onClick={() => handleBreadcrumbClick(index)}
              style={{ cursor: "pointer" }}
              sx={{ ...TextStyleInsidePaper, fontSize: "13px" }}
            >
              {item.name
                ? capitalizeFirstLetter(
                    item.url === "productdetail"
                      ? "Productdetails"
                      : item.url === "sellerpurchaseorderlist"
                      ? "Product PO (Sales)"
                      : item.name === "Dashboard"
                      ? "Wallet Dashboard"
                      : item.name
                  )
                : capitalizeFirstLetter(item.url)}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Paper>
  );
};

export default BreadCrumbHeader;

const maintext = {
  fontFamily: "Poppins",
  fontStyle: "SemiBold",
  fontWeight: 600,
  fontSize: {
    xl: "2.4rem",
    lg: "2.4rem",
    md: "2rem",
    sm: "1.8rem",
    xs: "1.6rem",
  },
  mt: 1,
};

const menutext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: "1rem",
  fontWeight: 500,
  color: "#807A7A",
};

const TextStyleInsidePaper = {
  display: "flex",
  alignItems: "center",
  gap: "10px",
  fontFamily: "Poppins",
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.3rem",
    sm: "1rem",
    xs: "1rem",
  },
  fontWeight: 500,
  color: "#6B7A99",
  lineHeight: "1.5",
  marginBottom: "2%",
};
