import { Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetInvoiceByOrderSummary } from "../../Hooks/Invoices/useGetInvoiceByOrderSummary";
import { useGetOrderSummaryByIdForBuyer } from "../../Hooks/OrderActions/useGetOrderSummaryByIdForBuyer";

import { getCompanyById } from "../../redux/action/CompanyActions";
import { getOrderSummary } from "../../redux/action/OrderSummaryActions";
import PI from "../BuyingJourneyPages/PI";
import PageLoader from "../../components/LoadingButton/PageLoader";
import MediaPI from "../BuyingJourneyPages/MediaPI";

const ProformaInvoice = () => {
  let dispatch = useDispatch();
  const [pdf, setPDF] = useState(false);
  const [StoreOrderId, setStoreOrderId] = useState("");
  const [storeTDS, setStoreTDS] = useState("");

  const { id } = useParams();
  const {
    data: InvoiceData,
    isLoading: DataLoading,
    error: DataError,
    refetch: InvoiceRefetch,
  } = useGetInvoiceByOrderSummary(id);

  const { data: orderSummaryData, isLoading: orderSummaryLoading } =
    useGetOrderSummaryByIdForBuyer(id);

  let orderData = [];

  for (let i = 0; i < orderSummaryData?.ProductData?.length; i++) {
    orderData.push(orderSummaryData.ProductData[i]);
  }

  let totalPrice = 0;
  orderData?.map((item) => {
    totalPrice += item.DiscountedPrice
      ? item.DiscountedPrice
      : item.PricePerUnit * item.ProductQuantity * item.ProductQuantity;
  });

  const { OrderSummary: OrderSummarydata, loading: OrderSummaryDataLoading } =
    useSelector((state) => state.OrderSummaryD);
  useEffect(() => {
    dispatch(getCompanyById(OrderSummarydata?.SellerCompanyId));
    dispatch(getOrderSummary(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      getCompanyById(orderSummaryData?.OrderSummarydata?.BuyerCompanyId)
    );
  }, [orderSummaryData?.OrderSummarydata?.BuyerCompanyId]);

  async function GetOrderByInvoice() {
    await axios.get(`order/get_order_by_orderSummaryId/${id}`).then((res) => {
      setStoreOrderId(res?.data?._id);
      setStoreTDS(res?.data);
    });
  }
  useEffect(() => {
    GetOrderByInvoice();
  }, []);

  if (DataLoading) {
    return <PageLoader />;
  } else {
    return (
      <Paper
        sx={{
          bgcolor: "transparent",
          boxShadow: "none",
          width: "100%",
        }}
        elevation={0}
      >
        {InvoiceData?._id ? (
          <>
            {InvoiceData?.ProductData?.at(0)?.ProductTypeName === "Media" ||
            InvoiceData?.IsMedia ? (
              <MediaPI
                SellerPage={true}
                ProformaInvoice={true}
                IsMedia={true}
                Title={"Media Proforma Invoice"}
                PageName={"Proforma Invoice"}
                TDS={storeTDS?.INRDetails?.TDS_PERCENT ?? 0.1}
              />
            ) : (
              <PI
                OrderId={StoreOrderId}
                SellerPage={true}
                ProformaInvoice={true}
                Title={"Proforma Invoice"}
                PageName={"Proforma Invoice"}
                downloadpdf={pdf}
                InvoiceId={InvoiceData?._id}
                TDS={storeTDS?.INRDetails?.TDS_PERCENT ?? 0.1}
              />
            )}
          </>
        ) : (
          <PageLoader />
        )}
      </Paper>
    );
  }
};

export default ProformaInvoice;
