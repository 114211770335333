import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Typography } from "@mui/material";
import "./ProgressBar.css";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const OrderStatus = ({ type, Data, userhubtype }) => {
  const [stepCount, setStepCount] = useState();
  const [usehubType, setUserHubType] = useState("");
  useEffect(() => {
    const storedVariable = localStorage.getItem("userhubtype");
    setUserHubType(storedVariable);
  }, []);

  const steps =
    usehubType === "seller"
      ? type === "mediaorderlist"
        ? [
            { num: 1, label: "PO Received" },
            { num: 2, label: "Seller Accepted PO" },
            { num: 3, label: "Seller Issued PI" },
            { num: 4, label: "Buyer Accepted PI" },
            { num: 5, label: "Payment Done" },
            { num: 6, label: "Product Delivered" },
            { num: 7, label: "Buyer Confirmed Delivery" },
            { num: 8, label: "Payment Released to Seller" },
          ]
        : type === "voucherorderlist"
        ? [
            { num: 1, label: "Payment Done" },
            { num: 2, label: "Voucher Activated by Seller" },
            { num: 3, label: "Buyer Confirmed Activation" },
            { num: 4, label: "Payment Released to Seller" },
          ]
        : [
            { num: 1, label: "PO Received" },
            { num: 2, label: "Seller Accepted PO" },
            { num: 3, label: "Seller Issued PI" },
            { num: 4, label: "Buyer Accepted PI" },
            { num: 5, label: "Logistic Selected" },
            { num: 6, label: "Payment Done" },
            { num: 7, label: "Product Delivered" },
            { num: 8, label: "Buyer Confirmed Delivery" },
            { num: 9, label: "Payment Released to Seller" },
          ]
      : type === "mediaorderlist"
      ? [
          { num: 1, label: "Generated PO" },
          { num: 2, label: "Buyer Issued PO" },
          { num: 3, label: "Seller Accepted PO" },
          { num: 4, label: "Buyer Accepted PI" },
          { num: 5, label: "Payment Done" },
          { num: 6, label: "Product Delivered" },
          { num: 7, label: "Buyer Confirmed Delivery" },
          { num: 8, label: "Payment Released to Seller" },
        ]
      : type === "voucherorderlist"
      ? [
          { num: 1, label: "Payment Done" },
          { num: 2, label: "Voucher Activated by Seller" },
          { num: 3, label: "Buyer Confirmed Activation" },
          { num: 4, label: "Payment Released to Seller" },
        ]
      : [
          { num: 1, label: "PO Received" },
          { num: 2, label: "Seller Accepted PO" },
          { num: 3, label: "Seller Issued PI" },
          { num: 4, label: "Buyer Accepted PI" },
          { num: 5, label: "Logistic Selected" },
          { num: 6, label: "Payment Done" },
          { num: 7, label: "Product Delivered" },
          { num: 8, label: "Buyer Confirmed Delivery" },
          { num: 9, label: "Payment Released to Seller" },
        ];

  const filteredSteps = steps.filter((step) => {
    if (type === "mediaorderlist") {
      return step?.num == stepCount;
    } else if (type === "voucherorderlist") {
      return step?.num == stepCount;
    }
    return false; // Default case for other types
  });

  const fetchOrderProgress = async () => {
    try {
      await axios
        .post(
          `order/get_order_progress_by_status`,
          { type: type, id: Data?._id },
          { withCredentials: true }
        )
        .then((res) => {
          setStepCount(res?.data?.step);
        });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (Data?._id) {
      fetchOrderProgress();
    }
  }, []);

  let lastElement = steps[steps.length - 1];

  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        sx={{
          ...TextStyle,
          color: usehubType === "seller" ? "#c64091" : "#2261a2",
        }}
      >
        {filteredSteps?.at(0)?.label}
      </Typography>
    </Box>
  );
};

export default OrderStatus;

const TextStyle = {
  color: "#2261A2",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 400,
};
