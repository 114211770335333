import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ProductAnalysis from "../../assets/SidebarIcon/ProductAnalysis.svg";
import PurchesOrder from "../../assets/SidebarIcon/Purchase Order.png";
import SellerDeliveryOrder from "../../assets/SidebarIcon/SellerDeliveryOrder.svg";
import Shipingproduct from "../../assets/SidebarIcon/Shipping Product.png";
import Shop from "../../assets/SidebarIcon/Shop.png";
import TrackYourOrder from "../../assets/SidebarIcon/TrackYourorder.svg";
import Trolley from "../../assets/SidebarIcon/Trolley.png";
import Vector from "../../assets/SidebarIcon/Vector.png";
// import WishlistIcon from "../../assets/SidebarIcon/Wishlist.png";
import PriceList from "../../assets/SidebarIcon/price-list 1.png";
import { Link } from "react-router-dom";
import newbarterLogo from "../../assets/SidebarIcon/BxiLogo.svg";
import { SidebarStyle } from "../../styles/sidebar.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TradeIcon from "../../assets/SidebarIcon/TradeIcon.svg";
import WishlistIcon from "../../assets/SidebarIcon/wishlistIcon.svg";

import {
  default as DashboardIcon,
  default as Dashboard_Active,
} from "../../assets/SidebarIcon/Dashboard.png";
import sendEvent from "../../utils/sendEvents";

const SellerSidebarRoutes = ({ companyTypeData, sendDataToParent }) => {
  const [tradeManagementExpanded, setTradeManagementExpanded] = useState(false);

  const sendData = (props) => {
    sendDataToParent(props);
  };
  const RouteArray = [
    {
      id: 1,
      name: "Wallet Dashboard",
      logoImage: DashboardIcon,
      activeLogo: Dashboard_Active,
      linkUrl: "/home/dashboard",
    },
    {
      id: 2,
      name: "Orders",
      logoImage: Trolley,
      activeLogo: "",
      linkUrl: "/home/sellerorderlist",
    },
    {
      id: 3,
      name: "Trade Management",
      logoImage: TradeIcon,
      linkUrl: "/home/myorderlist",
      tradeArray: [
        {
          id: 1,
          name: "Product Trade",
          linkUrl: "/home/sellerpurchaseorderlist",
        },
        {
          id: 2,
          name: "Product PI List",
          linkUrl: "/home/sellerpilist",
        },
        {
          id: 3,
          name:
            companyTypeData?.data?.CompanyTypeName === "Media"
              ? "Media Trade"
              : "Products Uploaded",
          linkUrl: "/home/sellermediapo",
        },
        {
          id: 4,
          name:
            companyTypeData?.data?.CompanyTypeName === "Media"
              ? "Media PI List"
              : "Products Uploaded",
          linkUrl: "/home/sellermediapilist",
        },
        {
          id: 5,
          name: "Voucher Trade",
          linkUrl: "/home/sellervoucherorderlist",
        },
      ],
    },
    {
      id: 4,
      name:
        companyTypeData?.data?.CompanyTypeName === "Media"
          ? "Media Uploaded"
          : "Products Uploaded",
      logoImage: Shipingproduct,
      activeLogo: "",
      linkUrl: "/home/sellerhub",
    },
    {
      id: 5,
      name: "Update Your Sales",
      logoImage: SellerDeliveryOrder,
      activeLogo: "",
      linkUrl: "/home/order_tracking",
    },
    {
      id: 6,
      name: "Product Analysis",
      logoImage: ProductAnalysis,
      activeLogo: "",
      linkUrl: "/home/productanalytics",
    },
    {
      id: 7,
      name: "BXI Payouts",
      logoImage: newbarterLogo,
      activeLogo: newbarterLogo,
      linkUrl: "/home/commissionlist",
    },
  ];

  const currentPath = window.location.pathname;

  const clearLocaStorageForBreadCrumb = () => {
    localStorage.removeItem("myArray");
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        maxHeight: "80vh",
        minHeight: "80vh",
        overflowY: "hidden",
        width: "100%",
        mt: "40px",
      }}
      elevation={0}
    >
      {RouteArray?.map((res, idx) => {
        return (
          <React.Fragment key={res.id}>
            {res?.name !== "Trade Management" ? (
              <Link
                to={res.linkUrl}
                onClick={() => {
                  sendData(false);
                  clearLocaStorageForBreadCrumb();
                }}
                style={{
                  textDecoration: "none",
                  width: "100%",
                  minWidth: "280px",
                }}
              >
                <Box
                  sx={{
                    ...SidebarButtonStyle,
                    background:
                      currentPath === res.linkUrl ? "#C83F91" : "transparent",
                    color: currentPath === res.linkUrl ? "#fff" : "#1D3163",
                  }}
                >
                  <img src={res.logoImage} width={"18px"} height="18px" />
                  {res?.name}
                </Box>
              </Link>
            ) : (
              <>
                <Button
                  sx={{
                    ...SidebarButtonStyle,
                    background: res.tradeArray.some(
                      (item) => item.linkUrl === currentPath
                    )
                      ? "#C83F91"
                      : "#fff",
                    color: res.tradeArray.some(
                      (item) => item.linkUrl === currentPath
                    )
                      ? "#fff"
                      : "#1D3163",
                    textTransform: "none",
                    width: "100%",
                    minWidth: "270px",
                  }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={() =>
                    setTradeManagementExpanded(!tradeManagementExpanded)
                  }
                >
                  <img src={res.logoImage} width={"18px"} height="18px" />
                  Trade Management
                </Button>

                <Collapse
                  in={tradeManagementExpanded}
                  timeout="auto"
                  unmountOnExit
                >
                  {" "}
                  <Box>
                    {res.tradeArray.map((tradeItem) => {
                      if (tradeItem?.name === "Products Uploaded") {
                        return null;
                      } else if (
                        (companyTypeData?.data?.CompanyTypeName ===
                          "Entertainment & Events" ||
                          companyTypeData?.data?.CompanyTypeName ===
                            "Airlines Tickets" ||
                          companyTypeData?.data?.CompanyTypeName === "Hotel") &&
                        (tradeItem?.name === "Product Trade" ||
                          tradeItem?.name === "Product PI List")
                      ) {
                        return null;
                      } else if (
                        companyTypeData?.data?.CompanyTypeName === "Media" &&
                        (tradeItem?.name === "Product Trade" ||
                          tradeItem?.name === "Product PI List" ||
                          tradeItem?.name === "Voucher Trade")
                      ) {
                        return null;
                      } else {
                        return (
                          <Link
                            key={tradeItem.id}
                            to={tradeItem.linkUrl}
                            onClick={() => {
                              sendData(false);
                              clearLocaStorageForBreadCrumb();
                            }}
                            style={{
                              textDecoration: "none",
                              width: "100%",
                              minWidth: "280px",
                            }}
                          >
                            <Box
                              sx={{
                                ...SidebarButtonStyle,
                                background:
                                  currentPath === tradeItem.linkUrl
                                    ? "#C83F91"
                                    : "transparent",
                                color:
                                  currentPath === tradeItem.linkUrl
                                    ? "#fff"
                                    : "#1D3163",
                              }}
                            >
                              {tradeItem.name}
                            </Box>
                          </Link>
                        );
                      }
                    })}{" "}
                  </Box>
                </Collapse>
              </>
            )}
          </React.Fragment>
        );
      })}
    </Paper>
  );
};

export default SellerSidebarRoutes;

const SidebarButtonStyle = {
  ":hover": {
    background: "#fff",
    border: "1px solid #C83F91",
  },
  border: "1px solid #fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "15px",
    lg: "15px",
    md: "15px",
    sm: "14px",
    xs: "14px",
  },
  width: "95%",
  maxWidth: "250px",

  height: "42px",
  borderRadius: "7px",
  cursor: "pointer",
  "&:hover": {
    color: "#C83F91",
  },
  fontWeight: "500",
  display: "flex",
  justifyContent: "flex-start",
  gap: "17px",
  alignContent: "center",
  alignItems: "center",
  paddingLeft: "20px",
};
